/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import {useState,useEffect} from 'react'
import { Wrapper
} from 'views/Home/indexStyles'
import { useWeb3React } from '@web3-react/core'
import { useHistory } from "react-router-dom";
import Login from 'components/Login'
import { Decimal } from "decimal.js";
import useWeb3 from 'hooks/useWeb3'
import useToast from 'hooks/useToast'
import { getNftStakingAddress,getPokemonNFTStakingAddress,getPegasusNftAddress, getMDFNftAddress,getPokemonNftAddress
} from 'utils/addressHelpers'
import { getNFTsStakingForSynchronous, getUserLevelTokens, getNFTsHttp } from 'utils/utils'
import { formatDecimal } from 'utils/math'
import { getNftStaking, getMDFNft, getPegasusNft, getPokemonNft, getPokemonNFTStaking  } from 'utils/change'
import { Button } from '@dreamweb/uikit'
import { Button as AntdButton, Modal } from 'antd'
import { TokenText, Stake, HarvestModal, Card, BigCard, CenterBox,BigCenterBox } from './indexStyles'




const arrPegasusName=['Pegasus Unicorn',
'Pegasus Normal',
'Pegasus Bronze',
'Pegasus Silver',
'Pegasus Gold']
const arrMDFName = ['MDF Normal',
'MDF Premium']


const arrPokemoname = ['Game']


const result = sessionStorage.getItem('gas') || 50000
export default function Index() {
  const history = useHistory()
  const web3 = useWeb3()
  const { account } = useWeb3React()
  // const account = '0x62F7294C0DfE9882af4f5484668Fa3EF3eD62612'
  // const account = '0x74191Ab012aa797441Ed2e37228F7Ef37528efE3'
  // const account = '0x866daEaC42E59FC1B940d0695555791D78AC8967'
  // const account = '0x1980daC68B89f09a593450DD7DBE44F6E7370CEB'
  // const account = '0x8b391F830326D3Eef0B3274eA2e3eA554cffa1ff'
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [totalStaking,setTotalStaking]=useState<any>()
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const [arrMyStaked,setMyStaked]=useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unLoading1, setUnLoading1] = useState(false);
  const [unLoading2, setUnLoading2] = useState(false);
  const [unLoading3, setUnLoading3] = useState(false);
  const [tabIndex, setTabIndex] = useState(0)
  const [arrApy,setArrApy]=useState<any>()
  const [isApproveNM1, setIsApproveNM1] = useState(false)
  const [isApproveNM2, setIsApproveNM2] = useState(false)
  const [isApproveNMpokemonNFT1, setIsApproveNMpokemonNFT1] = useState(false)
  const [isApproveNMpokemonNFT2, setIsApproveNMpokemonNFT2] = useState(false)
  const [isApproveNMpokemonNFT3, setIsApproveNMpokemonNFT3] = useState(false)
  const [arrEarend,setArrEarend]=useState<any>()
  const [openHarvest, setOpenHarvest] = useState(false)
  const [PegasusBalance, setPegasusBalance] = useState(0)
  const [pokemonbalance, setPokemonbalance] = useState(0)
  const [MDFbalance, setMDFbalance] = useState(0)
  const [idsPegasus, setIdsPegasus] = useState([])
  const [idsPegasusGame, setIdsPegasusGame] = useState([])
  const [idsMDF, setIdsMDF] = useState([])
  const [idsPokemon, setIdsPokemon] = useState([])
  const [timer,setTimer]=useState(null)
  const [loading,setLoading] = useState(false)
  const [loadingApprove,setLoadingApprove] = useState(false)
  const [loadingApprove2,setLoadingApprove2] = useState(false)
  const [loadingHarvest1,setLoadingHarvest1] = useState(false)
  const [loadingHarvest2,setLoadingHarvest2] = useState(false)
  const [loadingHarvest3,setLoadingHarvest3] = useState(false)
  const [idsUNM0, setIdsUNM0] = useState<any>([])
  const [idsUNM1, setIdsUNM1] = useState<any>([])
  const [idsUNM2, setIdsUNM2] = useState<any>([])
  
  // PEGASUS-NFT
  const pegasusNftContract = getPegasusNft(web3)
  // MDF-NFT
  const mdfNftContract = getMDFNft(web3)
  // 一级市场和PokemonNFt
  const pokemonNftContract = getPokemonNft(web3)
  
  // NFTStaking
  const NftStakingContract = getNftStaking(web3)

  // pokemonStakingContract
  const pokemonStakingContract = getPokemonNFTStaking(web3)


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  
  async function harvest(v) {
    try {
      if(v===0) {
        setLoadingHarvest1(true)
        const view = await NftStakingContract.methods.harvest(2, v).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
           })
      } else if(v===1) {
        setLoadingHarvest2(true)
        const view = await NftStakingContract.methods.harvest(2, v).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
           })
      } else if(v===2) {
        setLoadingHarvest3(true)
        const view = await pokemonStakingContract.methods.harvest(2, 0).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
           })
      }
      if(v===0) {
        setLoadingHarvest1(false)
      } else if(v===1) {
        setLoadingHarvest2(false)
      } else if(v===2) {
        setLoadingHarvest3(false)
      }
    } catch (error) {
      // console.log(error)
      if(v===0) {
        setLoadingHarvest1(false)
      } else if(v===1) {
        setLoadingHarvest2(false)
      } else if(v===2) {
        setLoadingHarvest3(false)
      }
    }
  }

  // 获取 miexd Pool  的数量 view
  const singlePegasusIdx = async () => {
    try {
      // console.log('获取 m 的数量 view')
      const view = await NftStakingContract.methods.miexdIdx().call()
      const viewPokemonNFT = await pokemonStakingContract.methods.miexdIdx().call()
      console.log('m 的数量 view', view, viewPokemonNFT)
      getNowAPY(view,viewPokemonNFT)
      if(account) {
        earend(view,viewPokemonNFT)
        myStaked(view,viewPokemonNFT)
      }
    } catch (error) {
      // console.log(error)
    }
  }

  
  // 获取全部期数数据 view
  const getUserTokens = async () => { 
    try {
      let term1 = {
        ids: [],
        lvs: []
      }
      let term2 = {
        ids: [],
        lvs: []
      }
      let term3 = {
        ids: [],
        lvs: []
      }
      
      
      // term1 = await getNFTsStakingForSynchronous(pegasusNftContract, account)  
      const balance = await pegasusNftContract.methods.balanceOf(account).call()
      console.log(497, balance)
      term1 =  await getNFTsHttp(account, getPegasusNftAddress(), balance, arrPegasusName)  
      console.log('MDF', term1)
      // console.log('getUserTokens', account, 20,1)
      // term1 = await pegasusNftContract.methods.getUserTokens(account, 20,1).call()
      const lvsGame = []; const idsGame = []
      const lvs = []; const ids=[]
      for (let index = 0; index < term1?.lvs?.length; index++) {
        if(term1?.lvs?.[index]===0) {
          idsGame.push(term1?.ids?.[index]);
          lvsGame.push(term1?.lvs?.[index]);
        }
      }
      for (let index = 0; index < term1?.lvs?.length; index++) {
        if(term1?.lvs?.[index]===1) {
          ids.push(term1?.ids?.[index]);
          lvs.push(term1?.lvs?.[index]);
          idsGame.push(term1?.ids?.[index]);
          lvsGame.push(term1?.lvs?.[index]);
        }
      }
      // console.log('MDF1', [...ids])
      // console.log(134, term1)

      
      // 获取Game对应质押飞马级别NFT
      setIdsPegasusGame([...idsGame])
      // 获取对应级别NFT
      setIdsPegasus([...ids])


      // term2 = await getNFTsStakingForSynchronous(mdfNftContract, account)   
      const balance2 = await mdfNftContract.methods.balanceOf(account).call()
      // term1 = await getNFTsNoSearch(MDFNftContract, account, currentPage)   
      term2 =  await getNFTsHttp(account, getMDFNftAddress(), balance2, arrMDFName) 
      // term2 = await mdfNftContract.methods.getUserTokens(account,20,1).call()
      
      const lvs5 = []; const ids5=[]
      for (let index = 0; index < term2?.lvs?.length; index++) {
        if(term2?.lvs?.[index]===0) {
          ids5.push(term2?.ids?.[index]);
          lvs5.push(term2?.lvs?.[index]);
        }
      }
      const lvs6 = []; const ids6=[]
      for (let index = 0; index < term2?.lvs?.length; index++) {
        if(term2?.lvs?.[index]===1) {
          ids6.push(term2?.ids?.[index]);
          lvs6.push(term2?.lvs?.[index]);
        }
      }
      console.log('setIdsMDF', term2, [...ids5,...ids6])
      // 所有MDF排序
      // setIdsMDF([...ids5,...ids6])
      // 最低MDF排序
      setIdsMDF([...ids5,...ids6])
      // term3 = await getUserLevelTokens(pokemonNftContract, account)  
      const balance3 = await pokemonNftContract.methods.balanceOf(account).call()  
      // term1 = await getUserLevelTokens(pokemonNftContract, account)    
      term3 =  await getNFTsHttp(account, getPokemonNftAddress(), balance3, arrPokemoname)  
      
      const lvs7 = []; const ids7=[]
      for (let index = 0; index < term3?.lvs?.length; index++) {
        ids7.push(term3?.ids?.[index]);
        lvs7.push(term3?.lvs?.[index]);
      }
      console.log('setIdsPokemon', term3, [...ids7])
      // console.log('term3', [...ids7])
      setIdsPokemon([...ids7])
    } catch (error) {
      console.log(198, error)
      // getUserTokens()
    }
  }

  // 获取当前展示
  useEffect(() => { 
    if(account && totalStaking?.pegasusLevel) {
      getUserTokens()
    }
  }, [account, totalStaking?.pegasusLevel])
  
  // 判断是否授权
  const isApprove = async () => { 
    const getPegasusBalance = await pegasusNftContract.methods.balanceOf(account).call()
    setPegasusBalance(getPegasusBalance)
    const getMDFbalance = await mdfNftContract.methods.balanceOf(account).call()
    setMDFbalance(getMDFbalance)
    const getPokemonbalance = await pokemonNftContract.methods.balanceOf(account).call()
    setPokemonbalance(getPokemonbalance)
    // console.log('isApprove', getMDFbalance)
    const res1 = await pegasusNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
    setIsApproveNM1(res1)
    const res2 = await mdfNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
    // console.log('isApproveMDFNftContract', res1, res2)
    setIsApproveNM2(res2)
  }  

  // 判断是否授权 pokemonNFT
  const isApprovePokemonNFT = async () => { 
    const res1 = await pegasusNftContract.methods.isApprovedForAll(account, getPokemonNFTStakingAddress()).call()
    setIsApproveNMpokemonNFT1(res1)
    const res2 = await mdfNftContract.methods.isApprovedForAll(account, getPokemonNFTStakingAddress()).call()
    setIsApproveNMpokemonNFT2(res2)
    const res3 = await pokemonNftContract.methods.isApprovedForAll(account, getPokemonNFTStakingAddress()).call()
    setIsApproveNMpokemonNFT3(res3)
    // console.log('isApprove', res1,res2,res3)
  }

  // 质押
  const stakeMixed = async (v)=>{
    setLoading(true)
    let ids1 = []
    let ids2 = []
    let ids3 = []
    if(v===0) {
      ids1 = idsPegasus.slice(0,5)
      ids2 = idsMDF.slice(0,1)
      try {
        console.log('stakeMixed',v,ids1,ids2)
        await NftStakingContract.methods.stakeMixed(v,ids1,ids2).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
        setLoading(false)
        toastSuccess('Stake successful')
      } catch (error:any) {
        setLoading(false)
        toastWarning(error?.message ? error?.message : 'Stake failed')
      }
    } else if(v===1) {
      ids1 = idsPegasus.slice(0,1)
      ids2 = idsMDF.slice(0,1)
      try {
        console.log('stakeMixed',v,ids1,ids2)
        await NftStakingContract.methods.stakeMixed(v,ids1,ids2).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
        setLoading(false)
        toastSuccess('Stake successful')
      } catch (error:any) {
        setLoading(false)
        toastWarning(error?.message ? error?.message : 'Stake failed')
      }
    } else if(v===2) {
      ids1 = idsPegasusGame.slice(0,1)
      ids2 = idsMDF.slice(0,1)
      ids3 = idsPokemon.slice(0,1)
      try {
        console.log('pokemonStakingContract',0,ids1,ids2,ids3)
        await pokemonStakingContract.methods.stakeMixed(0,ids1,ids2,ids3).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
        setLoading(false)
        toastSuccess('Stake successful')
      } catch (error:any) {
        console.log('质押报错', error)
        setLoading(false)
        toastWarning(error?.message ? error?.message : 'Stake failed')
      }
    }
    setLoading(false)
    getUserTokens()
    getUserMixedStakingTokens()
    isApprove()
    isApprovePokemonNFT()
    singlePegasusIdx()
  }

  // 查询质押的MixedPool view
  const getUserMixedStakingTokens = async () => {
    if(account) {
      const newIdsUNM0 = await NftStakingContract.methods.getUserMixedStakingTokens(2,0,account,500,1).call()
      const newIdsUNM1 = await NftStakingContract.methods.getUserMixedStakingTokens(2,1,account,500,1).call()
      console.log(323, newIdsUNM0, newIdsUNM1)
      console.log('newIdsUNM2参数','2','0','0x65803acA618808548b4D00Ad8b5cDA0e0aebA2D3',500,1)
      const newIdsUNM2 = await pokemonStakingContract.methods.getUserMixedStakingTokens(2,0,account,500,1).call()
      console.log('newIdsUNM2返回', newIdsUNM2)
      setIdsUNM1(newIdsUNM1)
      setIdsUNM0(newIdsUNM0)
      setIdsUNM2(newIdsUNM2)
      // console.log(188, newIdsUNM0, newIdsUNM1)
    }
  }

  // 取回 MIXED 的 NFT  do
  const withdrawMixed = async (v) => {
    try {
      if(v===0) {
        setUnLoading1(true)
        // console.log('取回 MIXED', v,idsUNM0?.[0]?.slice(0,5),[0,0,0,0,0],idsUNM0?.[1]?.slice(0,1),[0], idsUNM0)
        await NftStakingContract.methods.withdrawMixed(
          v,
          idsUNM0?.[0]?.slice(0,5),
          [0,0,0,0,0],
          idsUNM0?.[1]?.slice(0,1),
          [0],).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
        toastSuccess('Unstake successful')
        setUnLoading1(false)
      } else if(v===1) {
        setUnLoading2(true)
        // console.log('取回 MIXED', v,idsUNM1?.[0]?.slice(0,1),[0],idsUNM1?.[1]?.slice(0,1),[0])
        await NftStakingContract.methods.withdrawMixed(
          v,
          idsUNM1?.[0]?.slice(0,1),
          [0],
          idsUNM1?.[1]?.slice(0,1),
          [0]).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
        toastSuccess('Unstake successful')
        setUnLoading2(false)
      } else if(v===2) {
          console.log('取回 MIXED', 0, 
          0,
          idsUNM2?.[0]?.slice(0,1),
          [0],
          idsUNM2?.[1]?.slice(0,1),
          [0],
          idsUNM2?.[2]?.slice(0,1),
          [0])
          setUnLoading3(true)
          try {
            
          // console.log('取回 MIXED', v,idsUNM1?.[0]?.slice(0,1),[0],idsUNM1?.[1]?.slice(0,1),[0])
          await pokemonStakingContract.methods.withdrawMixed(
            0,
            idsUNM2?.[0]?.slice(0,1),
            [0],
            idsUNM2?.[1]?.slice(0,1),
            [0],
            idsUNM2?.[2]?.slice(0,1),
            [0]).send({
            from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
            
          })
          toastSuccess('Unstake successful')
          } catch (error) {
            console.log('取回报错',error)
          }
          setUnLoading3(false)

      }
    } catch (error:any) {
      if(v===0) { 
        setUnLoading1(false)
      } else if(v===1) {
        setUnLoading2(false)
      } else if(v===2) {
        setUnLoading3(false)
      }
      console.log(406, error)
      toastWarning(error?.message ? error?.message : 'Unstake failed')
    }
    getUserTokens()
    getUserMixedStakingTokens()
    isApprove()
    isApprovePokemonNFT()
    singlePegasusIdx()
  }

  


  
  const nftApprove = async (v) => {
    try {
      if(v===0||v===1) {
        setLoadingApprove(true) 
        if(!isApproveNM1){
          // console.log('nftContract', pegasusNftContract.methods)
          await pegasusNftContract.methods.setApprovalForAll(getNftStakingAddress(), true).send({ from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
             })
          // console.log('nftContract', pegasusNftContract.methods)
        } 
        if(!isApproveNM2) {
          // console.log('MDFNftContract', mdfNftContract.methods)
          await mdfNftContract.methods.setApprovalForAll(getNftStakingAddress(), true).send({ from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
             })
          // console.log('MDFNftContract', mdfNftContract.methods)
        }
        setLoadingApprove(false) 
      }
      if(v===2) {
        console.log(415, isApproveNMpokemonNFT1, isApproveNMpokemonNFT2, isApproveNMpokemonNFT3)
        setLoadingApprove2(true) 
        if(!isApproveNMpokemonNFT1){
          // console.log('nftContract', pegasusNftContract.methods)
          await pegasusNftContract.methods.setApprovalForAll(getPokemonNFTStakingAddress(), true).send({ from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
             })
          // console.log('nftContract', pegasusNftContract.methods)
        } 
        if(!isApproveNMpokemonNFT2) {
          // console.log('MDFNftContract', mdfNftContract.methods)
          await mdfNftContract.methods.setApprovalForAll(getPokemonNFTStakingAddress(), true).send({ from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
             })
          // console.log('MDFNftContract', mdfNftContract.methods)
        }
        if(!isApproveNMpokemonNFT3) {
          // console.log('pokemonNftContract', mdfNftContract.methods)
          await pokemonNftContract.methods.setApprovalForAll(getPokemonNFTStakingAddress(), true).send({ from: account,
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
             })
          // console.log('MDFNftContract', mdfNftContract.methods)
        }
        setLoadingApprove2(false) 

      }
      toastSuccess('Approve successful')
    } catch (error:any) {
      setLoadingApprove(false) 
      setLoadingApprove2(false) 
      toastWarning(error?.message ? error?.message : 'Approve failed')
    }
    isApprove()
    isApprovePokemonNFT()
  }
  
  useEffect(() => { 
    isApprove()
    isApprovePokemonNFT()
  }, [tabIndex, account])
  

  // 获取Earend
  const earend = async (v,viewPokemonNFT)=>{
    console.log('获取Earend', v, viewPokemonNFT, account)
    try {
      // console.log('获取Earend', 2, account)
      const newEarend = await Promise.all(
        [...new Array(+v)].map(async (item,index) => {
          const apy = await NftStakingContract.methods.earned(2, index, account).call()
          return new Promise((resolve) => {
            resolve(apy)
          })
        })
      )
      // console.log('获取Earend', 2, account)
      const newPokemonNFTEarend = await Promise.all(
        [...new Array(+viewPokemonNFT)].map(async (item,index) => {
          const apy = await pokemonStakingContract.methods.earned(2, index, account).call()
          return new Promise((resolve) => {
            resolve(apy)
          })
        })
      )
      // console.log('Earned', [...newEarend,...newPokemonNFTEarend])
      setArrEarend([...newEarend,...newPokemonNFTEarend])
    } catch (error) {
      // console.log(error)
    }
    clearInterval(timer)
    const newTimer = setInterval(async () => { 
      try {
        // console.log('获取Earend', 2, account)
        const newEarend = await Promise.all(
          [...new Array(+v)].map(async (item,index) => {
            const apy = await NftStakingContract.methods.earned(2, index, account).call()
            return new Promise((resolve) => {
              resolve(apy)
            })
          })
        )
        // console.log('获取Earend', 2, account)
        const newPokemonNFTEarend = await Promise.all(
          [...new Array(+viewPokemonNFT)].map(async (item,index) => {
            const apy = await pokemonStakingContract.methods.earned(2, index, account).call()
            return new Promise((resolve) => {
              resolve(apy)
            })
          })
        )
        // console.log('Earned', [...newEarend,...newPokemonNFTEarend])
        // console.log('Earned', newEarend)
        setArrEarend([...newEarend,...newPokemonNFTEarend])
      } catch (error) {
        // console.log(error)
      }
    }, 3000)
    setTimer(newTimer)
  }

  
  // 获取Total Staking
  const getTotalStaking = async () => { 
    try {
      // console.log('mixedPools获取Total Staking 获取 single MDF Pool 的数量 view')
      const view = await NftStakingContract.methods.mixedPools(0).call()
      // console.log('mixedPoolsTotal Staking single MDF Pool 的数量 view', view)
      setTotalStaking(view)
    } catch (error) {
      // console.log(error)
    }
  }


  const init = async () => { 
    singlePegasusIdx() 
    getTotalStaking()
    getUserMixedStakingTokens()
  }

  
  useEffect(() => { 
    init()
    return ()=>{
      clearInterval(timer)
    }
  },[account])
  // 获取 APY view
  const getNowAPY = async (view,viewPokemonNFT) => {
    console.log('mgetNowAPY获取 APY', view, viewPokemonNFT, account)
    try {
      // console.log('mgetNowAPY获取 APY 参数', 2, 1, account)
      const newArrApy = await Promise.all(
        [...new Array(+view)].map(async (item,index) => {
          const apy = await NftStakingContract.methods.getNowAPY(2, index, account || '0x5ED18b121382Ee7a1146d2801e54865d0e4ef825').call()
          return new Promise((resolve) => {
            resolve(apy)
          })
        })
      )
      const newPokemonNFTArrApy = await Promise.all(
        [...new Array(+viewPokemonNFT)].map(async (item,index) => {
          const apy = await pokemonStakingContract.methods.getNowAPY(2, index, account || '0x5ED18b121382Ee7a1146d2801e54865d0e4ef825').call()
          return new Promise((resolve) => {
            resolve(apy)
          })
        })
      )
      console.log('mAPY', [...newArrApy,...newPokemonNFTArrApy])
      setArrApy([...newArrApy,...newPokemonNFTArrApy])
    } catch (error) {
      // console.log(error)
    }
  }

  // 获取My Staked
  const myStaked = async (v,viewPokemonNFT)=>{
    console.log('获取My Staked',v,viewPokemonNFT, account)
    try {
      // console.log('获取My Staked', 2, account)
      const newMyStaked = await Promise.all(
        [...new Array(+v)].map(async (item,index) => {
          // console.log(282, index)
          const apy = await NftStakingContract.methods.getTokenLength(2, index, account).call()
          return new Promise((resolve) => {
            resolve(apy?.[1])
          })
        })
      )
      const newPokemonNFTMyStaked = await Promise.all(
        [...new Array(+viewPokemonNFT)].map(async (item,index) => {
          // console.log(282, index)
          const apy = await pokemonStakingContract.methods.getTokenLength(2, index, account).call()
          return new Promise((resolve) => {
            resolve(apy?.[1])
          })
        })
      )
      console.log('mMy Staked', [...newMyStaked,...newPokemonNFTMyStaked])
      setMyStaked([...newMyStaked,...newPokemonNFTMyStaked])
    } catch (error) {
      // console.log(error)
    }
  }

  

  const handleClick = async (index: number) => {
    setTabIndex(index)
  }
  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <TokenText isMobile={matches}>
            <main>
              <div className='main'>
                <div className='module1'>
                  <div className='text1'>Mixed Staking</div>
                  <div className='text2-box'>
                    <div className='text2-1'>Stake Mixed Nft to earn More tokens</div>
                  </div>
                </div>
              </div>
            </main>
          </TokenText>
          <Stake isMobile={matches}>
            <main>
              <div className='main'>
                <CenterBox isMobile={matches}>
                  <Card isMobile={matches}>
                    <div className='title'>5:1 combination staking</div>
                    <div style={{marginTop: '5px'}} className='title-x'>Stake 5 Pegasus Normal and 1 MDF earn Dcoin tokens</div>
                    <div style={{marginTop: '20px'}} className='line' />
                    <div className='content-box'>
                      <div className='new-img'>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse1.png" alt="" />
                          <img style={{position: 'absolute',width:matches?50:37,height:matches?50:37,top:matches?-19:-15,right:matches?-26:-18}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/x5.png" alt="" />
                        </div>
                        <div style={{fontSize:'16px',fontWeight: 600}}>+</div>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(-2deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse6.png" alt="" />
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(5deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse5.png" alt="" />
                        </div>
                      </div>
                      <div className='content'>
                        <div  style={{marginBottom: '30px'}} className='line' />
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>My Staked</div>
                          <div style={{color: '#EEEEEE'}}>{arrMyStaked?.[0]||0}</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>APY</div>
                          <div style={{color: '#EEEEEE'}}>{new Decimal(+arrApy?.[0]||0).div(100).toNumber()}%</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>Earned</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#EEEEEE'}}>~{formatDecimal(new Decimal(+arrEarend?.[0]||0).div(1e18).toNumber(), 4)}</div>
                          <Button disabled={!+arrMyStaked?.[0]||loadingHarvest1} onClick={()=>harvest(0)} style={{background: loadingHarvest1||!+arrEarend?.[0]? '#6D6D6D':'#fff', color: loadingHarvest1||!+arrEarend?.[0]? '#B9B9B9':'#000', width: '92px', height: '30px',  borderRadius: '4px'}}>
                          {
                              loadingHarvest1? 'Harvesting': 'Harvest'
                            }
                          </Button>
                        </div>
                        <div style={{marginTop: '15px'}} className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>Pegasus Normal balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsPegasus?.length||0} Pieces</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>MDF balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsMDF?.length} Pieces</div>
                        </div>
                        <div className='text-box'>
                          {
                            account ?
                            <>
                            {
                               isApproveNM1 && isApproveNM2 ? 
                               <>
                                <div style={{width:'220px',marginTop:'30px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={loading} disabled={idsMDF?.length<1||idsPegasus?.length/5<1}  onClick={()=>stakeMixed(0)} style={{color: "#000000", width:  matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px',border:'none', opacity: idsMDF?.length<1||idsPegasus?.length/5<1?'0.7':''}}>
                                      Stake
                                    </AntdButton>
                                  </div>
                                </div>
                                <div style={{width:'220px',marginTop:'30px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={unLoading1} disabled={arrMyStaked?.[0]<=0} onClick={()=>withdrawMixed(0)} style={{color: "#000000", width:  matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px', opacity: arrMyStaked?.[0]<=0?'0.7':''}}>
                                      Unstake & Claim
                                    </AntdButton>
                                  </div>
                                </div>
                               </> : 
                               <AntdButton loading={loadingApprove} onClick={()=>{nftApprove(0)}} style={{color: "#000000", width: '100%', height: '56px', background: '#FFFFFF', borderRadius: '4px',marginTop: '30px',border:'none'}}>
                                  Approve
                                </AntdButton>
                            }
                            </> :
                            <Login
                                style={{width:'100%',marginTop:'30px'}}
                                setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                            >
                              <div style={{width: '100%',height: '56px',background: '#fff', borderRadius: '4px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                            </Login>
                          }
                        </div>
                      </div>
                    </div>
                  </Card>
                </CenterBox>
                <CenterBox isMobile={matches}>
                  <Card isMobile={matches}>
                    <div className='title'>1:1 special combination staking</div>
                    <div style={{marginTop: '5px'}} className='title-x'>Stake 1 Pegasus Normal and 1 MDF Game earn Dcoin tokens</div>
                    <div style={{marginTop: '20px'}} className='line' />
                    <div className='content-box'>
                      <div className='new-img'>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(0deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse1.png" alt="" />
                        </div>
                        <div style={{fontSize:'16px',fontWeight: 600}}>+</div>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(-2deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse6.png" alt="" />
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(5deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse5.png" alt="" />
                        </div>
                      </div>
                      <div className='content'>
                        <div style={{marginBottom: '30px'}} className='line' />
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>My Staked</div>
                          <div style={{color: '#EEEEEE'}}>{arrMyStaked?.[1]||0}</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>APY</div>
                          <div style={{color: '#EEEEEE'}}>{new Decimal(+arrApy?.[1]||0).div(100).toNumber()}%</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>Earned</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#EEEEEE'}}>~{formatDecimal(new Decimal(+arrEarend?.[1]||0).div(1e18).toNumber(), 4)}</div>
                          <Button disabled={!+arrMyStaked?.[1]||loadingHarvest2} onClick={()=>harvest(1)} style={{ width: '92px', height: '30px', background: loadingHarvest2||!+arrEarend?.[1]? '#6D6D6D':'#fff', color: loadingHarvest2||!+arrEarend?.[1]? '#B9B9B9':'#000',  borderRadius: '4px'}}>
                            {
                              loadingHarvest2? 'Harvesting': 'Harvest'
                            }
                          </Button>
                        </div>
                        <div style={{marginTop: '15px'}} className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>Pegasus Normal balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsPegasus?.length||0} Pieces</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>MDF balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsMDF?.length} Pieces</div>
                        </div>
                        <div className='text-box'>
                          {
                            account ?
                            <>
                            {
                               isApproveNM1 && isApproveNM2 ? 
                               <>
                                <div style={{width:'220px',marginTop:'24px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={loading} disabled={idsMDF?.length<1||idsPegasus?.length<1} onClick={()=>stakeMixed(1)} style={{color: "#000000", width: matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px', opacity: idsMDF?.length<1||idsPegasus?.length<1?'0.7':''}}>
                                      Stake
                                    </AntdButton>
                                  </div>
                                </div>
                                <div style={{width:'220px',marginTop:'24px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={unLoading2} disabled={arrMyStaked?.[1]<=0} onClick={()=>withdrawMixed(1)} style={{color: "#000000", width:  matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px', opacity: arrMyStaked?.[1]<=0?'0.7':''}}>
                                      Unstake & Claim
                                    </AntdButton>
                                  </div>
                                </div>
                               </> : 
                               <AntdButton  loading={loadingApprove} onClick={()=>{nftApprove(1)}} style={{color: "#000000", width: '100%', height: '56px', background: '#FFFFFF', borderRadius: '4px',marginTop: '30px',border:'none'}}>
                                  Approve
                                </AntdButton>
                            }
                            </> :
                            <Login
                                style={{width:'100%',marginTop:'30px'}}
                                setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                            >
                              <div style={{width: '100%',height: '56px',background: '#fff', borderRadius: '4px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                            </Login>
                          }
                        </div>
                      </div>
                    </div>
                  </Card>
                </CenterBox>
              </div>
              <div className='main'>
                <BigCenterBox isMobile={matches}>
                  <BigCard isMobile={matches}>
                    <div className='title'>1:1:1 special combination staking</div>
                    <div style={{marginTop: '5px'}} className='title-x'>Stake 1 Pegasus Normal and 1 MDF and 1 Game earn Dcoin tokens</div>
                    <div style={{marginTop: '20px'}} className='line' />
                    <div className='content-box'>
                      <div className='new-img'>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(0deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse1.png" alt="" />
                        </div>
                        <div style={{fontSize:'16px',fontWeight: 600}}>+</div>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(-2deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse6.png" alt="" />
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(5deg)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse5.png" alt="" />
                        </div>
                        <div style={{fontSize:'16px',fontWeight: 600}}>+</div>
                        <div style={{position:'relative',width: matches?210:105,height:matches?210:105}}>
                          <img className='img-n' style={{position: 'absolute',transform: 'rotateZ(0deg)'}} src="	https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" />
                        </div>
                      </div>
                      <div className='content'>
                        <div style={{marginBottom: '30px'}} className='line' />
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>My Staked</div>
                          <div style={{color: '#EEEEEE'}}>{arrMyStaked?.[2]||0}</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>APY</div>
                          <div style={{color: '#EEEEEE'}}>{new Decimal(+arrApy?.[2]||0).div(100).toNumber()}%</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}}>Earned</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#EEEEEE'}}>~{formatDecimal(new Decimal(+arrEarend?.[2]||0).div(1e18).toNumber(), 4)}</div>
                          <Button disabled={!+arrMyStaked?.[2]||loadingHarvest3} onClick={()=>harvest(2)} style={{ width: '92px', height: '30px', background: loadingHarvest3||!+arrEarend?.[2]? '#6D6D6D':'#fff', color: loadingHarvest3||!+arrEarend?.[2]? '#B9B9B9':'#000',  borderRadius: '4px'}}>
                            {
                              loadingHarvest3? 'Harvesting': 'Harvest'
                            }
                          </Button>
                        </div>
                        <div style={{marginTop: '15px'}} className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>Pegasus Unicorn or Pegasus Normal balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsPegasusGame?.length||0} Pieces</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>MDF balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsMDF?.length} Pieces</div>
                        </div>
                        <div className='text-box'>
                          <div style={{color: '#8A8A8A'}} className='top'>Game balance</div>
                          <div style={{color: '#EEEEEE'}}>{idsPokemon?.length} Pieces</div>
                        </div>
                        <div className='text-box'>
                          {
                            account ?
                            <>
                            {
                               isApproveNMpokemonNFT1 && isApproveNMpokemonNFT2 && isApproveNMpokemonNFT3 ? 
                               <>
                                <div style={{width:'220px',marginTop:'24px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={loading} disabled={idsPokemon?.length<1||idsPegasusGame?.length<1||idsMDF?.length<1} onClick={()=>stakeMixed(2)} style={{color: "#000000", width: matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px', opacity: idsPokemon?.length<1||idsPegasusGame?.length<1||idsMDF?.length<1?'0.7':''}}>
                                      Stake
                                    </AntdButton>
                                  </div>
                                </div>
                                <div style={{width:'220px',marginTop:'24px'}}>
                                  <div className='btn'>
                                    <AntdButton loading={unLoading3} disabled={arrMyStaked?.[2]<=0} onClick={()=>withdrawMixed(2)} style={{color: "#000000", width:  matches?'100%':'95%', height: '56px', background: '#FFFFFF', borderRadius: '4px', opacity: arrMyStaked?.[2]<=0?'0.7':''}}>
                                      Unstake & Claim
                                    </AntdButton>
                                  </div>
                                </div>
                               </> : 
                               <AntdButton  loading={loadingApprove2} onClick={()=>{nftApprove(2)}} style={{color: "#000000", width: '100%', height: '56px', background: '#FFFFFF', borderRadius: '4px',marginTop: '30px',border:'none'}}>
                                  Approve
                                </AntdButton>
                            }
                            </> :
                            <Login
                                style={{width:'100%',marginTop:'30px'}}
                                setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                            >
                              <div style={{width: '100%',height: '56px',background: '#fff', borderRadius: '4px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                            </Login>
                          }
                        </div>
                      </div>
                    </div>
                  </BigCard>
                </BigCenterBox>
              </div>
            </main>
          </Stake>
          
          <Modal className='buy-tickets-modal' title={null} footer={null} open={openHarvest} closable={false}>
              <HarvestModal isMobile={matches}>
                <div className='title'>
                  Harvest
                </div>
                <div className='token'>
                  <div>Token:</div>
                  <div>123.4567</div>
                </div>
                <div className='btn btn-change'>
                  <Button className='Cancle'>Cancel</Button>
                  <Button >Buy Now</Button>
                </div>
              </HarvestModal>
          </Modal>
        </div>
      </main>
    </Wrapper>
  )
}
