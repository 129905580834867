/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, TokenAmount, WETH } from '@dreamfinance-libs/sdk'
import { Button, CardBody, AddIcon, Text as UIKitText } from '@dreamweb/uikit'
import { RouteComponentProps , useHistory, Route } from 'react-router-dom'
import { LightCard } from 'components/Card'
import { Decimal } from "decimal.js";
import { useWeb3React } from '@web3-react/core'
import { getBep20Contract } from 'utils/contractHelpers'
import { getPool, getFarm , getLp  } from 'utils/change'
import useWeb3 from 'hooks/useWeb3'
import { AutoColumn, ColumnCenter } from 'components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import CardNav from 'components/CardNav'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { AddRemoveTabs } from 'components/NavigationTabs'
import { MinimalPositionCard } from 'components/PositionCard'
import Row, { RowBetween, RowFlat } from 'components/Row'
import { PairState } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks/index'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { Field } from 'state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'state/mint/hooks'


import { useTransactionAdder } from 'state/transactions/hooks'
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from 'utils'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { currencyId } from 'utils/currencyId'
import Pane from 'components/Pane'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Login from 'components/Login'
import useI18n from 'hooks/useI18n'
import { useTokenBalance } from 'hooks/useTokenBalance'
import AppBody from '../AppBody'
import useToast from 'hooks/useToast'
import { Dots, Wrapper } from '../Liquidity/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'
import { ROUTER_ADDRESS } from '../../constants'
import { async } from 'q'


const result = sessionStorage.getItem('gas') || 50000
export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const web3 = useWeb3()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprovalA,setIsApprovalA] = useState(false)
  const [isApprovalB,setIsApprovalB] = useState(false)
  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  const TranslateString = useI18n()

  const oneCurrencyIsWBNB = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId])))
  )
  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const [deadline] = useUserDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')
  const [tokenAdd,setTokenAdd]=useState("0xE8182F12e2844ACa9b6268ABc6A1047b779eB2Eb")
  const [balance, setBalance] = useState(0)

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)

  const addTransaction = useTransactionAdder()
  
  
  const lpContract =  getLp(web3)

  // const cccc = async () => {
  //   console.log('ccc')
  //   const maxNum = await getBep20Contract('0x55d398326f99059ff775485246999027b3197955', web3).methods.allowance(account, view).call()
  //   console.log('ccc', maxNum)
  // }
  // cccc()

  // 获取tokens 合约地址 view
  const tokensn = async () => {
    try {
      // console.log(135,
      //   currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
      //   currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',)
      const view = await lpContract.methods.tokens(
        currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
        currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',).call()
      // console.log('tokensn', view)
      setTokenAdd(view)
      if(account) {
        const res1 = await getBep20Contract(view, web3).methods.allowance(account, view).call()
        // const res1 = await lpContract.methods.balanceOf(account).call()
        setBalance(res1)
        // console.log(138, res1)
      }
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => { 
    tokensn()
  }, [account, currencyA?.address, currencyB?.address])



  // 预估通过 amount0 需要多少 amount1
  const getPerAmount0ToExchangeAmount1 = async () => {
    try {
      // console.log('预估通过 amount0 需要多少 amount1', {
      //   token0: currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
      //   amount0: parsedAmounts[Field.CURRENCY_A],
      //   token1: currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
      // })
      // if(parsedAmounts[Field.CURRENCY_A]===undefined){
      //   onFieldBInput(null)
      //   return
      // }
      const view = await lpContract.methods.getPerAmount0ToExchangeAmount1(
        currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
        parsedAmounts[Field.CURRENCY_A]!==undefined?new Decimal(parsedAmounts[Field.CURRENCY_A].toFixed()).mul(1e18).toFixed():0,
        currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
      ).call()
      onFieldBInput(new Decimal(view).div(1e18).toFixed())
    } catch (error) {
      // console.log(error)
    }
  }

  async function onAdd() {
    try {
      setAttemptingTxn(true)
      // console.log('获得LP 参数', 
      //   currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
      //   new Decimal(parsedAmounts[Field.CURRENCY_A].toFixed()).mul(1e18).toFixed(),
      //   currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
      //   new Decimal(parsedAmounts[Field.CURRENCY_B].toFixed()).mul(1e18).toFixed(),
      // )
      const view = await lpContract.methods.mintLPs(
        currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
        new Decimal(parsedAmounts[Field.CURRENCY_A].toFixed()).mul(1e18).toFixed(),
        currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
        new Decimal(parsedAmounts[Field.CURRENCY_B].toFixed()).mul(1e18).toFixed(),
        ).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      toastSuccess('Success','Liquidity added successfully')
      setAttemptingTxn(false)
      handleDismissConfirmation()
    } catch (error:any) {
      setAttemptingTxn(false)
      // console.log(error?.message)
      toastWarning('Prompt', error?.message ? error?.message : 'Liquidity added fail')
    }
    handleDismissConfirmation()
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px" style={{ marginTop: '20px' }}>
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <UIKitText mr="8px">
              {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
            </UIKitText>
            {/* <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            /> */}
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px" style={{ marginTop: '20px' }}>
        <RowFlat style={{ marginTop: '20px' }}>
          <UIKitText color="textSubtle" mr="8px">{liquidityMinted?.toSignificant(6)}</UIKitText>
          {/* <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          /> */}
        </RowFlat>
        <Row>
          <UIKitText color="card" fontSize="18px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </UIKitText>
        </Row>
        <UIKitText color="card" small textAlign="left" padding="8px 0 0 0 " style={{ fontStyle: 'normal', color: '#fff' }}>
          {`Output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </UIKitText>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
        onDismiss={handleDismissConfirmation}

      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currA: Currency) => {
      const newCurrencyIdA = currencyId(currA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currB: Currency) => {
      const newCurrencyIdB = currencyId(currB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA || 'BNB'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    onFieldAInput('')
  }, [onFieldAInput])



  useEffect(() => { 
    getPerAmount0ToExchangeAmount1()
  }, [formattedAmounts[Field.CURRENCY_A]])

  useEffect(() => { 
    onFieldAInput(null) 
    onFieldBInput(null) 
    const unListen = history.listen((e) => { 
      onFieldAInput(null) 
      onFieldBInput(null) 
     })
    return ()=>{
      unListen()
    }
  }, [account])

  return (
    <div style={{paddingTop: '81px',display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',}}>
      {/* <CardNav activeIndex={1} /> */}
      <AppBody>
        <AddRemoveTabs adding />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={
                  noLiquidity
                    ? TranslateString(1154, 'Add Liquidity')
                    : TranslateString(1156, 'You will receive')
                }
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <CardBody>
            <AutoColumn gap="20px">
              {/* {noLiquidity && (
                <ColumnCenter>
                  <Pane>
                    <AutoColumn gap="12px">
                      <UIKitText>{TranslateString(1158, 'You are the first liquidity provider.')}</UIKitText>
                      <UIKitText>
                        {TranslateString(1160, 'The ratio of tokens you add will set the price of this Liquidity.')}
                      </UIKitText>
                      <UIKitText>
                        {TranslateString(1162, 'Once you are happy with the rate click supply to review.')}
                      </UIKitText>
                    </AutoColumn>
                  </Pane>
                </ColumnCenter>
              )} */}
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                onMax={() => {
                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                currency={currencies[Field.CURRENCY_A]}
                id="add-liquidity-input-tokena"
                showCommonBases={false}
              />
              <ColumnCenter>
                <AddIcon color="textSubtle" />
              </ColumnCenter>
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMax={() => {
                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                }}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                currency={currencies[Field.CURRENCY_B]}
                id="add-liquidity-input-tokenb"
                showCommonBases={false}
                disabled
              />
              {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
                <div>
                  <UIKitText
                    style={{ textTransform: 'uppercase', fontWeight: 600, margin: '10px 0' }}
                    color="text"
                    fontSize="14px"
                    mb="2px"
                  >
                    {noLiquidity
                      ? TranslateString(1164, 'Initial prices and Liquidity share')
                      : TranslateString(1166, 'Prices and Liquidity share')}
                  </UIKitText>
                  <Pane style={{ background: '#2D3339', border: 'none' }}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </Pane>
                </div>
              )}

              {!account ? (
                // <ConnectWalletButton width="100%" />
                <Login
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            >
              <div style={{color: '#000', background: '#fff', width: '340px', height: '56px' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
            </Login>
              ) : (
                <AutoColumn gap="md">
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (
                          <Button
                            onClick={async ()=>{
                              setIsApprovalA(true)
                              await approveACallback()
                              setIsApprovalA(false)
                            }}
                            disabled={isApprovalA||approvalA === ApprovalState.PENDING}
                            style={{ color: '#000', width: approvalB !== ApprovalState.APPROVED ? '48%' : '100%', opacity: approvalA === ApprovalState.PENDING ? '0.7': ''  }}
                          >
                            {approvalA === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                            )}
                          </Button>
                        )}
                        {approvalB !== ApprovalState.APPROVED && (
                          <Button
                            onClick={async ()=>{
                              setIsApprovalB(true)
                              await approveBCallback()
                              setIsApprovalB(false)
                            }}
                            disabled={isApprovalB||approvalB === ApprovalState.PENDING}
                            style={{ color: '#000', width: approvalA !== ApprovalState.APPROVED ? '48%' : '100%', opacity: approvalB === ApprovalState.PENDING ? '0.7': '' }}
                          >
                            {approvalB === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                            )}
                          </Button>
                        )}
                      </RowBetween>
                    )}
                  <Button
                    style={{color: '#000'}}
                    onClick={() => {
                      if (expertMode) {
                        onAdd()
                      } else {
                        setShowConfirm(true)
                      }
                    }}
                    disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                    variant={
                      !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                        ? 'danger'
                        : 'primary'
                    }
                    width="100%"
                  >
                    {error ?? 'Supply'}
                  </Button>
                </AutoColumn>
              )}
            </AutoColumn>
          </CardBody>
        </Wrapper>
      </AppBody>
      <AutoColumn style={{ marginTop: '1rem', width: '100%', maxWidth: '476px' }}>
        <MinimalPositionCard isUpdate={formattedAmounts[Field.CURRENCY_A]} address={tokenAdd} showUnwrapped={oneCurrencyIsWBNB} pair={pair} currency0={currencies[Field.CURRENCY_A]} currency1={currencies[Field.CURRENCY_B]}/>
      </AutoColumn>
    </div>
  )
}
