/* eslint-disable import/no-cycle */
/* eslint-disable no-debugger */
/* eslint-disable prefer-const */
/* eslint-disable no-await-in-loop */
/* eslint-disable one-var */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth'
import useToast from 'hooks/useToast'
import styled from 'styled-components'; 
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useScroll, useDeepCompareEffect } from 'ahooks' 
import DreamAbi from 'config/abi/dream.json'
import useWeb3 from 'hooks/useWeb3'
import Web3 from 'web3'
import web3NoAccount from 'utils/web3'
import { AbiItem } from 'web3-utils'
import { Dropdown, Drawer, Button, Popover, message, Input } from 'antd'
import Login from 'components/Login'
import contracts from 'config/constants/contracts'
import { interceptAdd, formatDateWithSlash, initLangage } from 'utils/utils'
// import translations from 'config/localization/en-US.json'
import { useHistory } from 'react-router-dom'
import { resources } from 'i18n/resources'
import { Banner1, Wrapper
} from './indexStyles'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}

export default function Index(props: any) {
  const { t } = useTranslation();
  const web3 = useWeb3()
  const { logout } = useAuth()
  const history = useHistory()
  const simpleTimer = useRef(null);
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [circulateText, setCirculateText] = useState('Simple');
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const { account } = useWeb3React()
  const kaAdd = contracts.dream?.[process && process.env.REACT_APP_CHAIN_ID]
  

  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  // 获取期数数量 view
  // const getRoundLenght = async () => {
  //   try {
  //     const termNum = await getContract(DreamAbi, kaAdd, web3).methods.getRoundLenght().call()
  //     getRoundLenghtNum.current = +termNum
  //     return termNum
  //   } catch (error) {
  //     return 0
  //   }
  // }
  
  useEffect(() => { 
    let num = 1
    simpleTimer.current = setInterval(() => {
      if(num%4 === 0) {
        setCirculateText(`Simple`)
      }
      if(num%4 === 1) {
        setCirculateText(`Easy`)
      }
      if(num%4 === 2) {
        setCirculateText(`Safe`)
      }
      if(num%4 === 3) {
        setCirculateText(`Innovative`)
      }
      num += 1
    }, 2000)
    return () => { 
      clearInterval(simpleTimer.current)
     }
  }, [])




  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <Banner1 isMobile={matches}>
            <main>
              <div className='banner1'>
                <div className='siw'>
                  <div>Dream Finance</div> 
                  <div><span>{circulateText}</span> into Web3</div>
                </div>
                <div className='banner1-img'>
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/home-banner1.png" alt="" />
                </div>
              </div>
              <div className='banner2'>
                <div className='banner2-img'>
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/home-banner2.png" alt="" />
                </div>
                <div className='banner2-main'>
                  <div className='banner2-main-change'>Change</div>
                  <div className='banner2-main-text'>{`DCOIN Coin is a new coin, it's on BSC Deployed and can change on any EVM Chain. `}</div>
                  <div className='banner2-main-text'>Total Supply: 1000 Billion</div>
                  <div className='icon-box'>
                    <div className='icon'>
                      <div className='icon-img'>
                        <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/BNB.png" alt="" />
                      </div>
                    </div>
                    <a 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/change')}}>
                      <div className='buy'>
                        Buy now
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='banner3'>
                <div className='banner3-item'>
                  <div className='banner3-item-img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/Farming.png" alt="" />
                  </div>
                  <div className='banner3-item-text'>Farming</div>
                  <div className='banner3-item-text banner3-item-text-2'>Application Scenario 1</div>
                </div>
                <div className='banner3-item'>
                  <div className='banner3-item-img'>
                    <img src="/images/staking.png" alt="" />
                  </div>
                  <div className='banner3-item-text'>Staking</div>
                  <div className='banner3-item-text banner3-item-text-2'>Application Scenario 2</div>
                </div>
                <div className='banner3-item'>
                  <div className='banner3-item-img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/Lottery.png" alt="" />
                  </div>
                  <div className='banner3-item-text'>Lottery</div>
                  <div className='banner3-item-text banner3-item-text-2'>Application Scenario 3</div>
                </div>
              </div>
              <div className='banner5'>
                <div className='banner5-img'>
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/home-banner5.png" alt="" />
                </div>
                <div className='banner5-main'>
                  <div className='banner5-main-change'>LP</div>
                  <div className='banner5-main-text'>Let everyone who provides LP get matching benefits.</div>
                </div>
              </div>
              <div className='banner6'>
                <div className='banner6-img'>
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/home-banner6.png" alt="" />
                </div>
                <div className='banner6-main'>
                  <div className='banner6-main-change'>Staking</div>
                  <div className='banner6-main-text'>NFT Holders can lock their NFT Tokens to get more DCOIN.</div>
                  <div className='banner6-main-text'>Users who hold tokens can obtain greater returns over a longer period of time.</div>
                </div>
              </div>
              <div className='banner7'>
                <div className='banner7-img'>
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/home-banner7.png" alt="" />
                </div>
                <div className='banner7-main'>
                  <div className='banner7-main-change'>Farm</div>
                  <div className='banner7-main-text'>Liquidity providers and DCOIN providers can farm more DCOIN rewards.</div>
                  <div className='banner7-main-text'>Earn DCOIN and become a member of the Stargate community.</div>
                </div>
              </div>
            </main>
          </Banner1>
        </div>
      </main>
    </Wrapper>
  )
}
