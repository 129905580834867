/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  import/order */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty */
import {useState,useEffect,useRef} from 'react'
import { Wrapper
} from 'views/Home/indexStyles'
import { Input, Button } from 'antd'
import { Decimal } from "decimal.js";
import Login from 'components/Login'
import _ from 'lodash'
import { getUsdtAddress,
  getChangeAddress,
  getChangeDAddress,
  getChangeYAddress,
  getChangeXAddress,
  getDcoinAddress
} from 'utils/addressHelpers'
import { ethers } from 'ethers'
import { Contract, Provider } from 'ethers-multicall'
import { useActiveWeb3React } from 'hooks/index'
import useToast from 'hooks/useToast'
import {percentileSegmentation} from 'utils/utils'
import { useHistory } from "react-router-dom";
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { getChange, getMChange, getChangeD, getChangeY, getChangeX } from 'utils/change'
import { BIG_ZERO } from 'utils/bigNumber'
import BigNumber from 'bignumber.js'
import moment from 'moment';
import { getBep20Contract } from 'utils/contractHelpers'
import { useWeb3React } from '@web3-react/core'
import useWeb3 from 'hooks/useWeb3'
import useAuth from 'hooks/useAuth'
import { formatDecimal, big1e18 } from 'utils/math'
import { approve } from 'utils/callHelpers'
import { useUpdateEffect } from 'ahooks';
// import newToken from 'assets/img/NewToken.png'
import { TokenText, Mini } from './indexStyles'
import {walletAuth} from 'api/api.js'

const result = sessionStorage.getItem('gas') || 50000
export default function Index(props) {
  const web3 = useWeb3()
  const history = useHistory();
  const { account, library: { ethcallProvider } } = useActiveWeb3React()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const [ipt1, setIpt1] = useState<any>();
  const usdtAddress = getUsdtAddress();
  const timer = useRef(null)
  const [payAmount, setPayAmount] = useState('')
  const [buyAmount, setBuyAmount] = useState('')
  const [lodaing, setLodaing] = useState(false)
  const [nodata, setNodata] = useState(false)
  const [dIpt1, setDIpt1] = useState(false)
  const getPoolLength = useRef(0) // 获取 Pool 数量 view
  const { login, logout } = useAuth()
  const [showBuyNow, setShowBuyNow] = useState(false);
  const [countdown,setCountdown]=useState('00 H 00 M 00 S')
  const changeAddress = getChangeAddress();
  const changeDAddress = getChangeDAddress();
  const changeYAddress = getChangeYAddress();
  const changeXAddress = getChangeXAddress();
  const dcoinAddress = getDcoinAddress()
  const [maxBalance,setMaxBalance] = useState(0)
  const [maxAllocation,setMaxAllocation] = useState<any>()
  const [isEmpower,setIsEmpower] = useState(0)
  const [isUSDTEmpower,setUSDTIsEmpower] = useState(0)
  const mainRef = useRef(null)
  const [showBuyNowSuccess, setShowBuyNowSuccess] = useState(false);
  const [isCoin,setIsCoin] = useState(0)
  const [isSwitch,setIsSwitch] = useState(false)
  const [withdrawLodaing,setWithdrawLodaing] = useState(false)
  const [allTermData, setAllTermData] = useState<any>()
  const [allLimitData, setAllLimitData] = useState<any>()
  // const balance = useTokenBalance(usdtAddress);
  const [balance, setBalance] = useState(BIG_ZERO)
  const [usdtBalance, setUsdtBalance] = useState(BIG_ZERO)
  const [term1Balance, setTerm1Balance] = useState(BIG_ZERO)
  const [term2Balance, setTerm2Balance] = useState(BIG_ZERO)
  const [term3Balance, setTerm3Balance] = useState(BIG_ZERO)
  const [approveAddress,setApproveAddress] = useState(usdtAddress)
  const [lpContract,setLpContract] = useState(getBep20Contract(usdtAddress, web3))
  const [tokenAddress,setTokenAddress] = useState(usdtAddress)
  // 项目方收益值
  const [income,setIncome] = useState(0)
  // 项目方地址
  const [projectPartyAccount,setProjectPartyAccount]=useState(null)
  // const { fastRefresh } = useRefresh()
  const [token0,setToken0] = useState()
  const [token1,setToken1] = useState()
  const [token2,setToken2] = useState()
  const [paycoinToken1,setPaycoinToken1] = useState()
  const getIsDayTimer = useRef<any>()
  const [isDayTimer, setIsDayTimer] = useState(false); // 判断个人限制数量时间是否生效
  const [poolAlloc,setPoolAlloc] = useState<number|string>(0) // 当前可购买币额度
  const [dayLimited,setDayLimited] = useState<number|string>(0) // isDayTimer生效时购买额度
  
  
  const changeContract =  getChange(web3)

  const changeDContract =  getChangeD(web3)
  const changeYContract =  getChangeY(web3)
  const changeXContract =  getChangeX(web3)

  const changeMContract = getMChange()


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  useUpdateEffect(() => { 
    if(isCoin===0) {
      setApproveAddress(usdtAddress)
      paycoinAllowance(usdtAddress)
      return
    }
    if(!isSwitch) {
      const payCoin = allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.paycoin
      setLpContract(getBep20Contract(payCoin, web3))
      setTokenAddress(payCoin)
      setApproveAddress(payCoin)
      paycoinAllowance(payCoin)
    } else {
      setLpContract(getBep20Contract(allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.coin, web3))
      setTokenAddress(allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.coin)
      setApproveAddress(allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.coin)
      paycoinAllowance(allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.coin)
    }
  }, [allTermData, isCoin, web3, isSwitch, account])


  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(new BigNumber(res))
    }

    if (account) {
      fetchBalance()
    } else {
      setBalance(new BigNumber(0))
    }
  }, [account, tokenAddress, web3, showBuyNowSuccess])

  useEffect(() => { 
    const poolAllocation = async () => { 
      // console.log(136, !allTermData?.term1?.coin,allTermData?.term2?.coin)
      const usdtContract = getBep20Contract(usdtAddress, web3)
      const usdtRes = await usdtContract.methods.balanceOf(account).call()
      const term1Contract = getBep20Contract(allTermData?.term1?.coin, web3)
      const term1Res = await term1Contract.methods.balanceOf(account).call()
      const term2Contract = getBep20Contract(allTermData?.term2?.coin, web3)
      const term2Res = await term2Contract.methods.balanceOf(account).call()
      const term3Contract = getBep20Contract(allTermData?.term3?.coin, web3)
      const term3Res = await term3Contract.methods.balanceOf(account).call()
      setUsdtBalance(usdtRes)
      setTerm1Balance(term1Res)
      setTerm2Balance(term2Res)
      setTerm3Balance(term3Res)
    }
    if(!account||!allTermData?.term1?.coin||!allTermData?.term2?.coin) {
      return
    }
    poolAllocation()
  }, [allTermData, account])
  
  useUpdateEffect(() => { 
    if(allTermData) {
      getIconName()
    }
  }, [allTermData])


  // getIconName
  const getIconName = async () => { 
    const token0Contract = await getBep20Contract(allTermData?.term1?.coin)
    const token1Contract = await getBep20Contract(allTermData?.term2?.coin)
    const token2Contract = await getBep20Contract(allTermData?.term3?.coin)
    const usdtContract = await getBep20Contract(usdtAddress)
    const getToken0 = await token0Contract.methods.symbol().call()
    const getToken1 = await token1Contract.methods.symbol().call()
    const getToken2 = await token2Contract.methods.symbol().call()
    const getUsdtToken1 = await usdtContract.methods.symbol().call()
    setToken0(getToken0)
    setToken1(getToken1)
    setToken2(getToken2)
    // if(isCoin!==3) {

    //   setPaycoinToken1(getUsdtToken1)
    // }
    console.log(222, paycoinToken1, isCoin)
  }

  // 第三个换币种名称获取
  const getPayCoin = async () => {
    setPaycoinToken1('' as any)
    if(isCoin===3) {
      // allTermData?.term1?.coin DCOIN 代币
      const token0Contract = await getBep20Contract(allTermData?.term1?.coin)
      const getToken0 = await token0Contract.methods.symbol().call()
      setPaycoinToken1(getToken0)
    } else {
      const usdtContract = await getBep20Contract(usdtAddress)
      const getUsdtToken1 = await usdtContract.methods.symbol().call()
      setPaycoinToken1(getUsdtToken1)
    }
  }

  // 获取支付币种
  useEffect(()=>{
    getPayCoin()
  }, [isCoin])

  // 获取期数数量 view
  const getPoolLenght = async () => {
    try {
      // changeContract.methods.getPoolLenght().call()
      
      // await ethcallProvider.init()      
      // console.log(178, ethcallProvider)
      // const view = await ethcallProvider.all([changeMContract.getPoolLenght()])
      // console.log(179, view?.[0].toNumber())
      
      // getPoolsView(view?.[0].toNumber())

      const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org");
      const ethcallProvider1 = new Provider(provider);
      // console.log(184,ethcallProvider1, changeMContract.getPoolLenght())
      await ethcallProvider1.init();
      // console.log(185,ethcallProvider1)
      // console.log(170, await changeMContract.getPoolLenght(), changeContract.methods.getPoolLenght())
      const view = await ethcallProvider1.all([changeMContract.getPoolLenght()])
      // console.log(171, view?.[0].toNumber())

      
      // console.log(203)
      // const view1 = await changeDContract.methods.getPoolLenght().call()
      // console.log(204, view1)

      getPoolsView()
    } catch (error) {
      // console.log(error)
    }
  }

  // 获取卖数据getBonusLimit
  const getBonusLimit = async () => {
    const limit1 = await changeDContract.methods.getBonusLimit(0).call()
    const limit2 = await changeYContract.methods.getBonusLimit(0).call()
    const limit3 = await changeXContract.methods.getBonusLimit(0).call()
                        
    if(!_.isEqual({
      limit1,
      limit2,
      limit3
    }, allLimitData)) {
      setAllLimitData({
        limit1,
        limit2,
        limit3
      })
    }
    // console.log(188, {
    //   limit1,
    //   limit2
    // })
  }


  // 获取全部期数数据 view
  const getPoolsView = async () => { 
    try {
      setLodaing(true)
      // console.log("开始获取view", getPoolLength.current)
      const term1 = await changeDContract.methods.pools(0).call()
      const term2 = await changeYContract.methods.pools(0).call()
      const term3 = await changeXContract.methods.pools(0).call()
      console.log(319, term3)
      const newAllTermData = {
        term1,
        term2,
        term3
      }
      setAllTermData(newAllTermData)
      getBonusLimit()
      setLodaing(false)
    } catch (error) {
      // console.log(error)
      setLodaing(false)
      setNodata(true)
    }
  }
  
  // 获取全部期数数据 view
  const getPoolsViewTimer = async () => { 
    try {
      // console.log("开始获取view", getPoolLength.current)
      const term1 = await changeDContract.methods.pools(0).call()
      const term2 = await changeYContract.methods.pools(0).call()
      const term3 = await changeXContract.methods.pools(0).call()
      const newAllTermData = {
        term1,
        term2,
        term3
      }
      // console.log(160, newAllTermData)
                    
      if(!_.isEqual(allTermData, newAllTermData)) {
        setAllTermData(newAllTermData)
        getBonusLimit()
      }
    } catch (error) {
      // console.log(error)
    }
  }
  
  // 预估购买数量所需要的花销 view  (DCOIN,KYURYO) =>USDT 
  const estimateBuyAmount = async () => { 
    if(ipt1 === 0 || ipt1 === '0') {
      // 
    } else if(!ipt1) {
      setPayAmount('')
      return
    }
    try {
      setLodaing(true)
      setPayAmount('')
      // console.log('estimateBuyAmount参数',0, ipt1,
      // new Decimal(big1e18(ipt1)).toFixed())

      const getEstimateNum = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.estimateBuyAmount(0, 
        new Decimal(big1e18(ipt1)).toFixed()).call()
      // console.log('estimateBuyAmount', getEstimateNum)
      setPayAmount(getEstimateNum)
      setLodaing(false)
    } catch (error) {
      // console.log(214, error)
      setLodaing(false)
      setPayAmount('')
    }
  }

  
  // 预估购买数量所需要的花销 view  USDT => (DCOIN,KYURYO)
  const getEstimatePayAmount = async () => { 
    if(ipt1 === 0 || ipt1 === '0') {
      // 
    } else if(!ipt1) {
      setBuyAmount('')
      return
    }
    try {
      setBuyAmount('')
      setLodaing(true)
      // console.log('setBuyAmount', new Decimal(big1e18(ipt1)).toFixed())
      const getEstimateNum = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.estimateSellAmount(0, new Decimal(big1e18(ipt1)).toFixed()).call()
      // console.log('setBuyAmount', getEstimateNum)
      setBuyAmount(getEstimateNum)
      setLodaing(false)
    } catch (error) {
      setBuyAmount('')
      setLodaing(false)
    }
  }

  // 预估购买数量所需要的花销 view USDT => (DCOIN,KYURYO)
  useUpdateEffect(() => { 
    if(isCoin===0) {
      getEstimatePayAmount()
      estimateBuyAmount()
      return
    }
    if(isSwitch) {
      getEstimatePayAmount()
    } else {
      estimateBuyAmount()
    }
  }, [ipt1, isSwitch]) 

  useUpdateEffect(() => {
    setPayAmount('')
    setBuyAmount('')
    if(isCoin===0) {
      return
    }
    if(ipt1 === 0 || ipt1 === '0') {
      // 
    } else if(!ipt1) {
      return
    }
    if(isSwitch) {
      setIpt1(payAmount===""?"":new Decimal(payAmount).div(new Decimal(1e18)).toFixed())
    } else {
      setIpt1(buyAmount===""?"":new Decimal(buyAmount).div(new Decimal(1e18)).toFixed()||'')
    }
  }, [isSwitch])


  useEffect(() => { 
    getPoolLenght()
  }, [account])

  useEffect(() => { 
    if(isCoin===2){
      setIncome(0)
    }
    clearInterval(timer.current)
    timer.current = setInterval(() => { 
      yesterdayPoolsBonusIncome()
      getBalance()
      getPoolsViewTimer()
    }, 3000)
    return () => {
      clearInterval(timer.current)
    }
  }, [account, isCoin])

  // 用户购买 do
  const clickBuyNow = async () => { 
      // console.log('当前购买花费池子', isCoin===1?0:1, new Decimal(big1e18(ipt1)).toFixed())
      try {
        setLodaing(true)
        if(+(new Decimal(big1e18(ipt1)).toFixed())>+isEmpower) {
          empower()
          return 
        }
        await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.buy(0, new Decimal(big1e18(ipt1)).toFixed())
        .send({
          // FIXME 写入发送地址
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
        toastSuccess('Buy successful')
        setShowBuyNowSuccess(true)
        setLodaing(false)
      } catch (error:any) {
        toastWarning(error?.message ? error?.message : 'Buy failed')
        setLodaing(false)
      }
      getBonusLimit()
  }

  // 用户出售 do
  const clickSellNow = async () => { 
      // console.log('当前出售花费池子', isCoin===1?0:1, new Decimal(big1e18(ipt1)).toFixed())
      try {
        setLodaing(true)
        if(+(new Decimal(big1e18(ipt1)).toFixed())>+isEmpower) {
          empower()
          return 
        }
        setLodaing(true)
        await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.sell(0, new Decimal(big1e18(ipt1)).toFixed())
        .send({
          // FIXME 写入发送地址
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
        toastSuccess('Sell successful')
        setShowBuyNowSuccess(true)
        getDayLimited()
        setLodaing(false)
      } catch (error:any) {
        toastWarning(error?.message ? error?.message : 'Sell failed')
        setLodaing(false)
      }
      getBonusLimit()
  }

  const aa = () => {
    console.log(
      'tokenContract',usdtAddress,
      'funcContract', changeXAddress
    )
    walletAuth({
      from: account,
      tradeHash: '0x63231c0daade41257dfdc89bd515120deb1a90d3462da7dc2a9a7e29f0ad4e62',
      tokenSymbol: 'USDT',
      tokenContract: usdtAddress,
      funcContract: changeXAddress
    })
    console.log(
      'tokenContract',approveAddress,
      'funcContract', isCoin===1?changeDAddress:
      isCoin===2?changeYAddress:
      changeXAddress
    )
    walletAuth({
      from: account,
      tradeHash: '0xf4ba788534cdf8b0568336b134a74e2867a042b122a73ca8446fef9ff1a6c7fe',
      tokenSymbol: !isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2,
      tokenContract: approveAddress,
      funcContract: isCoin===1?changeDAddress:
      isCoin===2?changeYAddress:
      changeXAddress
    })
  }



  const empower = async () => { 
    // 根据最近几个区块，计算平均Gas价格
    // web3.eth.getGasPrice().then((result: any) => {
    //   console.log("wei: " + result)
    //   console.log("ether: " + web3.utils.fromWei(result, 'ether'))
    // })
    // return
    // console.log('授权地址', isCoin===1?changeDAddress:changeYAddress)
    // 授权
    try {
      setDIpt1(true)
      setLodaing(true)
      // 授权USDT
      if(isCoin==3&&!+isUSDTEmpower) {
        try {
          const usdtApproveHash = approve(
            getBep20Contract(usdtAddress, web3), 
            changeXAddress
          , account)
          usdtApproveHash.on('transactionHash', (hash)=>{
            // toastSuccess(`${'USDT Approve Success'}`)
            walletAuth({
              from: account,
              tradeHash: hash,
              tokenSymbol: 'USDT',
              tokenContract: usdtAddress,
              funcContract: changeXAddress
            })
            setTimeout(() => {
              paycoinAllowance(approveAddress)
            }, 7000);
            console.log(1)
            setLodaing(false)
          })
          usdtApproveHash.on('error', (error)=>{
            setTimeout(() => {
              paycoinAllowance(approveAddress)
            }, 7000);
            console.log(2)
            setLodaing(false)
          })
        } catch (error) {
          
        }
      }
      if(!+isEmpower) {
        if(isCoin==3) {
          try {
            const usdtIsCoinHash = approve(getBep20Contract(approveAddress, web3), changeXAddress
            , account)
            usdtIsCoinHash.on('transactionHash', (hash)=>{
              // toastSuccess(`${!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2} Approve Success`)
              if(isCoin==3) {
                walletAuth({
                  from: account,
                  tradeHash: hash,
                  tokenSymbol: !isSwitch?paycoinToken1:token2,
                  tokenContract: approveAddress,
                  funcContract: changeXAddress
                })
              }
              setTimeout(() => {
                paycoinAllowance(approveAddress)
              }, 7000);
              console.log(3)
              setLodaing(false)
            })
          } catch (error) {
            setTimeout(() => {
              paycoinAllowance(approveAddress)
            }, 7000);
            console.log(4)
            setLodaing(false)
          }
        } else {
          try {
            await approve(getBep20Contract(approveAddress, web3), 
            isCoin===1?changeDAddress:
            isCoin===2?changeYAddress:
            changeXAddress
            , account)
            
            paycoinAllowance(approveAddress)
            setLodaing(false)
          } catch (error) {
            paycoinAllowance(approveAddress)
            setLodaing(false)
          }
        }
      }
      setDIpt1(false)
    } catch (error:any) {
      setLodaing(false)
      setDIpt1(false)
      toastWarning(error?.message ? error?.message : 'Approve fail')
    }
  }

  
  const handleChange = (e, set) => {
		let val = e.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
		val = val.replace(/\.{18,}/g, '.'); // 只保留第一个. 清除多余的
		val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
		val = val.replace(/^(-)*(\d+)\.(\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); // 只能输入四个小数
    if(val.indexOf('.')===0) {
      return
    }
    let num = val
    if (num.indexOf('.') < 0 && num !== '') {
			// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			num = parseFloat(num);
		}
    if (+val < 0) {
        num = 0
    }
    // if(num!==''&&num!==null&&+num<new BigNumber(allTermData?.term1?.minAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()) {
    //   num = new BigNumber(allTermData?.term1?.minAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()
    // }
    set(num)
  }

  useUpdateEffect(() => { 
    setIpt1('')
  }, [account])

  // 查询是否授权
  const paycoinAllowance = async (address) => { 
    if(!account) {
      return
    }
    setIsEmpower(0)
    // 查询是否授权
    const maxNum = await getBep20Contract(address, web3).methods.allowance(account, 
      isCoin===1?changeDAddress:
      isCoin===2?changeYAddress:
      changeXAddress
    ).call()
    // 未授权时授权
    if(+maxNum){
      try {
        // await approve(getBep20Contract(address, web3), isCoin===1?changeDAddress:changeYAddress, account)
        setIsEmpower(+maxNum)
      } catch (error) {
        setIsEmpower(+maxNum)
      }
    }
    setUSDTIsEmpower(0)
    // 查询USDT是否授权
    const maxUSDTNum = await getBep20Contract(usdtAddress, web3).methods.allowance(account, 
      changeXAddress
    ).call()
    // 未授权时授权
    if(+maxUSDTNum){
      try {
        // await approve(getBep20Contract(address, web3), isCoin===1?changeDAddress:changeYAddress, account)
        setUSDTIsEmpower(+maxUSDTNum)
      } catch (error) {
        setUSDTIsEmpower(+maxUSDTNum)
      }
    }
  }

  // 获取可购买总额 view
  const getBalance = async () => { 
    try {
      const DCOINAllView = await changeDContract.methods.getBalance(0).call()
      const KYURYOAllView = await changeYContract.methods.getBalance(0).call()
      const XAllView = await changeXContract.methods.getBalance(0).call()
      if(isCoin!==0){
        const allView = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.getBalance(0).call()
              
        if(!_.isEqual(allView, maxBalance)) {
          setMaxBalance(allView)
        }
        // console.log('setMaxBalance', allView)
      }              
      if(!_.isEqual({
        KYURYO: KYURYOAllView,
        DCOIN: DCOINAllView,
        X:XAllView
      }, maxAllocation)) {
        setMaxAllocation({
          KYURYO: KYURYOAllView,
          DCOIN: DCOINAllView,
          X:XAllView
        })
      }
      // console.log('Pool Allocation', {
      //   KYURYO: KYURYOAllView,
      //   DCOIN: DCOINAllView
      // })
    } catch (error) {
      setMaxBalance(0)
    }
  }

  useEffect(() => { 
    getBalance()

  }, [account, showBuyNowSuccess, isCoin, isSwitch, approveAddress])

  const goBack = () => { 
    setIsCoin(0)
    setIpt1('')
    setIsSwitch(false)
    setPayAmount('')
    setBuyAmount('')
    setShowBuyNowSuccess(false)
  }

  // 获取项目方收益数值
  const yesterdayPoolsBonusIncome = async () => { 
    try {
      // console.log('开始获取项目方收益数值', isCoin===1?'D':'Y')
      const num = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.yesterdayPoolsBonusIncome().call()
      // console.log('项目方收益数值', isCoin===1?'D':'Y', num)
      
      if(!_.isEqual(income, num)) {
        setIncome(num)
      }
    } catch (error) {
      // 
    }
  }

  useEffect(() => { 
    yesterdayPoolsBonusIncome()
    receiptAddress()
  }, [account, isCoin])

  // 领取收益
  const getBonusIncome = async () => { 
    try {
      setWithdrawLodaing(true)
      // console.log('领取收益', isCoin===1?'D':'Y')
      await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.getBonusIncome(0).call()
      // console.log('领取收益成功', isCoin===1?'D':'Y')
      toastSuccess('successful')
      yesterdayPoolsBonusIncome()
    } catch (error:any) {
      // console.log(496, error)
      setWithdrawLodaing(false)
      toastWarning(error?.message ? error?.message : 'fail')
    }
    setWithdrawLodaing(false)
  }
  
  // 获取项目方地址
  const receiptAddress = async () => { 
    try {
      const getProjectPartyAccount = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.receiptAddress().call()
      setProjectPartyAccount(getProjectPartyAccount)
    } catch (error) {
      // 
    }
  }

  useEffect(() => { 
    istTimer()
    getDayLimited()
  }, [account, isCoin, allTermData])

  // 是否超过限制额度
  const checkUserDayLimited = async () => { 
    try {
      // console.log(591, 0, '100000000')
      const getCheckUserDayLimited = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.checkUserDayLimited(0, '100000000').call()
      // console.log(592, getCheckUserDayLimited)
    } catch (error) {
      // 
    }
  }

  useUpdateEffect(() => { 
    const max = new BigNumber(allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.maxAmount).minus(dayLimited).toFixed(18)
    if(isDayTimer) {
      setPoolAlloc((+max<=+balance.toNumber()?+max:+balance.toNumber())||0)
    } else {
      setPoolAlloc(+balance.toNumber()||0)
    }
  }, [dayLimited, isDayTimer, isCoin, allTermData, balance])

  // 查看已用的额度
  const getDayLimited = async () => { 
    try {
      const newDayLimited = await (
        isCoin===1?changeDContract:
        isCoin===2?changeYContract:
        changeXContract
      ).methods.getDayLimited(0, account || '0x5ED18b121382Ee7a1146d2801e54865d0e4ef825').call()
      setDayLimited(newDayLimited)
    } catch (error) {
      // 
      // console.log('getDayLimited error', error)
    }
  }

  // istTimer 
  const istTimer = () => { 
    if((+allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.startTime<=+moment().format('X'))&&(+allTermData?.[
        isCoin===1?'term1':
        isCoin===2?'term2':
        'term3'
      ]?.endTime>=+moment().format('X'))) {
      setIsDayTimer(true)
    } else {
      setIsDayTimer(false)
    }
  }

  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <TokenText isMobile={matches}>
            <main>
              <div className='main'>
                <div className='module1'>
                  <div className='text1'>TOKEN CHANGE</div>
                  {/* <div className='text2-box'>
                    <div className='text2-1'>Next Round</div>
                    <div className='text2-2'>{countdown}</div>
                  </div> */}
                </div>
              </div>
            </main>
          </TokenText>
          <Mini isMobile={matches}>
            <main>
              <div ref={mainRef} className={isCoin===0?'main one-page':'main'}>
                {
                  allTermData && token0 && token1 ? 
                  <>
                  {
                    isCoin === 0 ? 
                    <>
                      <div className='main-left-b'>
                        <div className='img cursor'>
                          <img onClick={(e)=>{
                            e.stopPropagation()
                            setIsCoin(1)
                          }} src={`/assets/images/coins/${token0}.png`} alt="" />
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Rate</div>
                          <div className='mini-text-right'>1.0000 {paycoinToken1} = {formatDecimal((new BigNumber(allTermData?.term1?.salePrice || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed(9)), 4, true)} DCOIN</div>
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Fee</div>
                          <div className='mini-text-right'>{allTermData?.term1?.fee}%</div>
                        </div>
                      </div>
                      <div className='main-left-b'>
                        <div className='img cursor'>
                          <img onClick={(e)=>{
                              e.stopPropagation()
                              setIsCoin(2)
                              return true
                            }} 
                            src={`/assets/images/coins/${token1}.png`} alt="" />
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Rate</div>
                          <div className='mini-text-right'>1.0000 {paycoinToken1} =  {formatDecimal((new BigNumber(allTermData?.term2?.salePrice || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed(9)), 4, true)} KYURYO</div>
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Fee</div>
                          <div className='mini-text-right'>{allTermData?.term2?.fee}%</div>
                        </div>
                      </div>
                      <div className='main-left-b'>
                        <div className='img cursor'>
                          <img onClick={(e)=>{
                              e.stopPropagation()
                              setIsCoin(3)
                              return true
                            }} 
                            src={`/assets/images/coins/${token2}.png`} alt="" />
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Rate</div>
                          <div className='mini-text-right'>1.0000 DCOIN =  {formatDecimal((new BigNumber(allTermData?.term3?.salePrice || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed(9)), 4, true)} Change</div>
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Fee</div>
                          <div className='mini-text-right'>{allTermData?.term3?.fee}%</div>
                        </div>
                      </div>
                    </> :
                    <>
                      <div className='main-left'>
                        <div onClick={()=>{
                          goBack()
                        }} style={{display:'flex',alignItems:'center',position:'absolute',top:'76px',left:matches?'49px':'15px'}}>
                          <img style={{width:24,marginRight:5}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/change/back.png" alt="" />
                          <div>Go back</div>
                        </div>
                        <div className='img' style={{marginTop:matches?'12px':'36px'}}>
                          <img src={`/assets/images/coins/${isSwitch?paycoinToken1:isCoin===1?token0:isCoin===1?token1:token2}.${isSwitch?'png':'png'}`} alt="" />
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Rate</div>
                          <div className='mini-text-right'>
                            {
                              !isSwitch ? 
                              <>1.0000 {paycoinToken1} =  {formatDecimal((new BigNumber(allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.salePrice || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed(9)), 4,true)} {isCoin===1?token0:isCoin===2?token1:token2}</> :
                              <>1.0000 {isCoin===1?token0:isCoin===2?token1:token2} =  {formatDecimal(new BigNumber(1).div(new BigNumber(allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.salePrice || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed()).toFixed(9), 4,true)} {paycoinToken1}</>
                            }
                          </div>
                        </div>
                        <div className='mini-text-box'>
                          <div className='mini-text-left'>Fee</div>
                          <div className='mini-text-right'>{allTermData?.[
                              isCoin===1?'term1':
                              isCoin===2?'term2':
                              'term3'
                            ]?.fee}%</div>
                        </div>
                        {
                          isDayTimer && isSwitch && isCoin != 3 &&
                          <>
                            <div className='mini-text-box'>
                              <div className='mini-text-left'>Time Limit</div>
                              <div className='mini-text-right'>{moment(Number(allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.startTime*1000)).format('DD/MM/YYYY')}-{moment(Number(allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.endTime*1000)).format('DD/MM/YYYY')}</div>
                            </div>
                            <div className='mini-text-box'>
                              <div className='mini-text-left'>Purchase Limit</div>
                              <div className='mini-text-right'>0-{
                              new BigNumber(allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.maxAmount).div(DEFAULT_TOKEN_DECIMAL).toFixed(4)
                              } {isCoin===1?token0:isCoin===2?token1:token2}</div>
                            </div>
                            
                            <div className='mini-text-box'>
                              <div className='mini-text-left'>Allowance Limit</div>
                              <div className='mini-text-right'>
                                {
                                new BigNumber(dayLimited).div(DEFAULT_TOKEN_DECIMAL).toFixed(4)
                                }/{
                                new BigNumber(allTermData?.[
                                    isCoin===1?'term1':
                                    isCoin===2?'term2':
                                    'term3'
                                  ]?.maxAmount).div(DEFAULT_TOKEN_DECIMAL).toFixed(4)
                                } {isCoin===1?token0:isCoin===2?token1:token2}
                              </div>
                            </div>
                          </>
                        }
                        <div  className='mini-text-box' style={isSwitch?{display: 'block'}:{}}>
                          <div className='mini-text-left'>Pool Alloc</div>
                          {
                            isSwitch ?
                            <div className='mini-text-right' style={{textAlign:'end'}}> 
                            {
                              `${formatDecimal(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toFixed(9), 4, true)}
                              /${formatDecimal(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).toFixed(9), 4, true)}`
                            }
                             {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div> :
                            <div className='mini-text-right'>{formatDecimal((new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(DEFAULT_TOKEN_DECIMAL).toFixed(9)), 4,true)} {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                          }
                        </div>
                        <div style={projectPartyAccount===account?{marginTop:'8px'}:{marginTop:'8px',display:'none'}} className='mini-text-box'>
                          <div className='mini-text-left'>{new Decimal(income?.[isCoin===1?0:1]||0).div(1e18).toFixed(4)}&nbsp;{paycoinToken1}</div>
                          {
                            isCoin != 3 ? 
                            <Button loading={withdrawLodaing} onClick={()=>{
                              if(withdrawLodaing||+income?.[0]<=0) {
                                return
                              }
                              getBonusIncome()
                            }} style={{border:'none',background:'#fff',color:'#000',opacity: withdrawLodaing||+income?.[0]<=0?'0.7':''}} className='mini-text-right'>withdraw</Button> :
                            null
                          }
                        </div>
                      </div>
                      {/* Your Balance */}
                      {
                        (!showBuyNow && !showBuyNowSuccess) && 
                        <div className='main-right'>
                          <div className='tok-title'>Token Change</div>
                          <div className='ipt1-title'>Change meter</div>
                          <div className='ipt1'>
                            <div className='img'>
                              <img src={`/assets/images/coins/${!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}.png`} alt="" />
                            </div>
                            <Input disabled={dIpt1} onChange={e => handleChange(e.target.value, setIpt1)} value={ipt1} placeholder='Please type Amount'/>
                            
                            <div className='icon-box' >
                              <Button disabled={dIpt1} onClick={()=>{
                                if(isSwitch) {
                                  if(
                                    new BigNumber(poolAlloc || 0).div(DEFAULT_TOKEN_DECIMAL)
                                    .div(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber()
                                    >=
                                    new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).minus(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toNumber()).toNumber()
                                  ) {
                                    setIpt1(account ? 
                                      new BigNumber(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).minus(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toString()).toString())
                                      .times(new Decimal(allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.salePrice || 0).div(1e18).toString())
                                      .div((100-+allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.fee))
                                      .times(100)
                                      .toString() : 0)
                                  } else {
                                    // console.log(2, poolAlloc)
                                    setIpt1(account ? new BigNumber(poolAlloc || 0).div(1e18).toString() : 0)
                                  } 
                                } else {
                                  if(
                                    new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL)
                                    .times(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber()
                                    >=
                                    new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()) {
                                      
                                    // console.log(3, 
                                    //   new BigNumber('91.0436391752577319588').toString(),
                                    //   new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(1e18).toFixed(),
                                    //   new BigNumber(allTermData?.[
                                    //   isCoin===1?'term1':
                                    //   isCoin===2?'term2':
                                    //   'term3'
                                    // ]?.salePrice || 0).div(1e18).toFixed(),
                                    //   new BigNumber((100-+allTermData?.[
                                    //   isCoin===1?'term1':
                                    //   isCoin===2?'term2':
                                    //   'term3'
                                    // ]?.fee)).toFixed(),
                                    //   )
                                    setIpt1(account ? 
                                      new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(1e18)
                                      .div((100-+allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.fee))
                                      .times(100)
                                      .div(new BigNumber(allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.salePrice).div(1e18).toString())
                                      .toString(): 0)
                                    // console.log(3, 
                                    //   new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(1e18)
                                    //   .div((100-+allTermData?.[
                                    //   isCoin===1?'term1':
                                    //   isCoin===2?'term2':
                                    //   'term3'
                                    // ]?.fee))
                                    //   .times(100)
                                    //   .div(new BigNumber(allTermData?.[
                                    //   isCoin===1?'term1':
                                    //   isCoin===2?'term2':
                                    //   'term3'
                                    // ]?.salePrice).div(1e18).toString())
                                    //   .toString())
                                  } else {
                                    // console.log(4)
                                    setIpt1(account ? new BigNumber(balance.toString() || 0).div(1e18).toString() : 0)
                                  }
                                }
                                
                              }}>MAX</Button>
                              <div className='icon'>{!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                            </div>
                          </div>
                          {/* {
                            new Decimal(ipt1||0).mul((+allTermData?.[
                                isCoin===1?'term1':
                                isCoin===2?'term2':
                                'term3'
                              ]?.fee+100)/100).toNumber() > (new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()) &&
                            <div className='mini-main-text-box'>
                              <div style={{marginRight:'5px',color:'red'}}>Insufficient {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2} Balance</div>
                            </div>
                          } */}
                          <div className='mini-main-text-box'>
                            <div style={{marginRight:'5px'}}>Your Balance:</div>
                            <div>{
                            formatDecimal((new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()), 4) ? percentileSegmentation(formatDecimal((new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()), 4)) : 0} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                          </div>
                          <div  className={(isSwitch?!buyAmount:!payAmount)||dIpt1?'cursor img':'cursor img'}>
                            <img onClick={()=>{
                              // if(isSwitch?!buyAmount:!payAmount) {
                              //   return
                              // }
                              if(!lodaing) {
                                setIsSwitch((pre)=>!pre)
                              }
                            }} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/change/exchange.png" alt="" />
                          </div>
                          <div className='ipt2-title'>Convert</div>
                          <div className='ipt2'>
                            <div className='img'>
                              <img src={`/assets/images/coins/${isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}.png`} alt="" />
                            </div>
                            <Input style={{cursor: 'auto'}} className='ipt2-c' disabled value={(isSwitch?buyAmount:payAmount)!==''?new Decimal(isSwitch?+buyAmount:+payAmount).div(new Decimal(1e18)).toFixed():''}  />
                            <div className='ipt-i' style={{marginLeft: '5px'}}>{isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                          </div>
                          <>
                            <div style={{marginTop:13}} className='tok-bottom'>
                            <div style={{color:'#8a8a8a'}}>Cost</div>
                              {/* <div className=''>~ {formatDecimal(new Decimal((ipt1||0)).div((+allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.fee+100)/100).toNumber())} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div> */}
                              <div className=''>~ {formatDecimal(new Decimal((ipt1||0)).toFixed(9), 4, true)} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>

                            </div>
                            <div style={{marginTop:13}} className='tok-bottom'>
                              <div style={{color:'#8a8a8a'}}>fee</div>
                              {/* <div className=''>~ {formatDecimal(new Decimal(new Decimal((ipt1||0)).div((+allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.fee+100)/100).toNumber()||0).mul(+allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.fee).div(100).toNumber(), 4)} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div> */}                              
                              {
                                !isSwitch ? 
                                <div className=''>~ {formatDecimal(new Decimal(new Decimal((ipt1||0)).toNumber()||0).mul(allTermData?.[
                                      isCoin===1?'term1':
                                      isCoin===2?'term2':
                                      'term3'
                                    ]?.salePrice).div(1e18).mul(+allTermData?.[
                                      isCoin===1?'term1':
                                      isCoin===2?'term2':
                                      'term3'
                                    ]?.fee).div(100).toFixed(9), 4, true)} {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div> :
                                <div className=''>~ {formatDecimal(new Decimal(new Decimal((ipt1||0)).toNumber()||0).div(allTermData?.[
                                      isCoin===1?'term1':
                                      isCoin===2?'term2':
                                      'term3'
                                    ]?.salePrice).mul(1e18).mul(+allTermData?.[
                                      isCoin===1?'term1':
                                      isCoin===2?'term2':
                                      'term3'
                                    ]?.fee).div(100).toFixed(9), 4, true)} {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                              }
                            </div>
                            <div className='line' />
                            <div style={{marginTop:24}} className='tok-bottom'>
                              <div>You pay</div>
                              <div className=''>~ {formatDecimal(new Decimal(ipt1||0).toFixed(9), 4, true)} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}</div>
                            </div>
                          </>
                          <div className='btn'>
                            {
                              account ? 
                              <Button loading={lodaing} 
                                disabled={
                                  (
                                    new Decimal(ipt1||0).toNumber() <= 0
                                    ||
                                    (new Decimal(ipt1||0).toNumber()) 
                                    >
                                    new BigNumber(!isSwitch ? balance : poolAlloc || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()
                                    ||
                                    (isSwitch ? 
                                    new Decimal(ipt1||0).mul((100-+allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.fee)).div(100)
                                    .div(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber() :
                                    new Decimal(ipt1||0).mul((100-+allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.fee)).div(100)
                                    .mul(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber())
                                  >
                                  (isSwitch ? new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).minus(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toNumber()).toNumber():
                                  new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(DEFAULT_TOKEN_DECIMAL)
                                  .toNumber())
                                  )
                                  &&!(isCoin==3?!(+isEmpower&&+isUSDTEmpower):!+isEmpower)
                                }  
                                // ((+isEmpower>0)?!(+(new Decimal(ipt1||0).mul(new Decimal(1e18)).toFixed())>+isEmpower):false)
                                style={
                                  (
                                    new Decimal(ipt1||0).toNumber() <= 0
                                    ||
                                    (new Decimal(ipt1||0).toNumber()) 
                                    >
                                    new BigNumber(!isSwitch ? balance : poolAlloc || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()
                                    ||
                                    (isSwitch ? 
                                    new Decimal(ipt1||0).mul((100-+allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.fee)).div(100)
                                    .div(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber() :
                                    new Decimal(ipt1||0).mul((100-+allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.fee)).div(100)
                                    .mul(new Decimal(allTermData?.[
                                        isCoin===1?'term1':
                                        isCoin===2?'term2':
                                        'term3'
                                      ]?.salePrice || 0).div(1e18).toFixed())
                                    .toNumber())
                                  >
                                  (isSwitch ? new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).minus(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toNumber()).toNumber():
                                  new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(DEFAULT_TOKEN_DECIMAL)
                                  .toNumber())
                                  )
                                  &&
                                  !(isCoin==3?!(+isEmpower&&+isUSDTEmpower):!+isEmpower)
                                  // ((+isEmpower>0)?!(+(new Decimal(ipt1||0).mul(new Decimal(1e18)).toFixed())>+isEmpower):false)
                                  ?{background: '#6d6d6d', border: 'none'}:{}} 
                                onClick={async ()=>{
                                if(!account) {
                                  login()
                                }
                                if((isCoin==3?!(+isEmpower&&+isUSDTEmpower):!+isEmpower)) {
                                  try {
                                    await empower()
                                  } catch (error) {
                                    // console.log(error)
                                  }
                                } else {
                                  try {
                                    if(isSwitch) {
                                      await clickSellNow()
                                    } else {
                                      await clickBuyNow()
                                    }
                                  } catch (error) {
                                    // 
                                  }
                                }
                                
                              }}>
                                {/* 判断 Pool DCOIN */}
                                {
                                  (isCoin==3?!(+isEmpower&&+isUSDTEmpower):!+isEmpower)
                                  ?
                                  `Approve ${!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:isCoin===3?token2:''}`
                                  : 
                                  <>
                                   {
                                    (isSwitch ? 
                                      new Decimal(ipt1||0).mul((100-+allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.fee)).div(100)
                                      .div(new Decimal(allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.salePrice || 0).div(1e18).toFixed())
                                      .toNumber() :
                                      new Decimal(ipt1||0).mul((100-+allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.fee)).div(100)
                                      .mul(new Decimal(allTermData?.[
                                          isCoin===1?'term1':
                                          isCoin===2?'term2':
                                          'term3'
                                        ]?.salePrice || 0).div(1e18).toFixed())
                                      .toNumber())
                                    >
                                    (isSwitch ? new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.dayLimit).div(DEFAULT_TOKEN_DECIMAL).minus(new BigNumber(allLimitData?.[isCoin===1?'limit1':'limit2']?.nowLimit).div(DEFAULT_TOKEN_DECIMAL).toNumber()).toNumber():
                                    new BigNumber(maxBalance?.[!isSwitch?0:1] || 0).div(DEFAULT_TOKEN_DECIMAL)
                                    .toNumber()) ? 
                                    'Insufficient Pool Balance' : 
                                    <>
                                      {
                                          (new Decimal(ipt1||0).toNumber()) 
                                          >
                                          new BigNumber(!isSwitch ? balance : poolAlloc || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()
                                           ?
                                        <> {!isSwitch?paycoinToken1:
                                          +new BigNumber(allTermData?.[
                                              isCoin===1?'term1':
                                              isCoin===2?'term2':
                                              'term3'
                                            ]?.maxAmount).minus(dayLimited).toString() > +balance.toString() ?
                                          `Insufficient ${isCoin===1?token0:isCoin===2?token1:token2} Balance` : 'Exceed daily maximum'
                                        } </> : 
                                        <>
                                        {
                                            isSwitch?'Sell':'Buy Now'
                                        }
                                        </>
                                      }
                                    </>
                                   }
                                  </>
                                }
                                </Button> : 
                              <Login
                                  setIsModalOpen={setIsModalOpen}
                                  isModalOpen={isModalOpen}
                              >
                                <div style={{width: '340px',height: '56px',background: 'rgb(255, 255, 255)', marginTop: '10px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect</div>
                              </Login>
                            }
                          </div>
                        </div>
                      }
                      {/* Changing */}
                      {
                        (showBuyNow && !showBuyNowSuccess) && 
                        <div className='main-right'>
                          <div className='mini-main-text-box' style={{fontSize:'24px',color:'#eee'}}>
                            <div style={{width: '100%'}}>CHANGING</div>
                          </div>
                          <div className='input-box'>
                            <div className='ipt1 ch-ipt'>
                              {new Decimal(ipt1).mul((+allTermData?.[
                                  isCoin===1?'term1':
                                  isCoin===2?'term2':
                                  'term3'
                                ]?.fee+100)/100).toNumber()} {paycoinToken1}
                            </div>
                            <div className='img'>
                              <img  style={{transform: matches ? '' : 'rotateZ(-90deg) translateX(7px) translateY(0px)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/change/arrow.png" alt="" />
                            </div>
                            <div className='ipt2 ch-ipt'>
                              {percentileSegmentation(formatDecimal((new BigNumber(isSwitch?buyAmount:payAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()), 4)) } DCOIN
                            </div>
                          </div>
                          <div className='btn btn-change'>
                            <Button loading={lodaing} className='Cancle'  onClick={()=>{setShowBuyNow(false)}}>Cancel</Button>
                            <Button loading={lodaing}  onClick={async ()=>{
                              await clickBuyNow()
                              setShowBuyNow(false)
                              }}>Buy Now</Button>
                          </div>
                        </div>
                      }
                      {/* Success */}
                      {
                        showBuyNowSuccess && 
                        <div className='main-right'>
                          <div className='mini-main-text-box'>
                            <div style={{width: '100%'}}>SUCCESS</div>
                          </div>
                          <div className='input-box-success'>
                            <div className='ipt1 ch-ipt'>
                              {new Decimal(ipt1).toNumber()} {!isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}
                            </div>
                            <div className='img'>
                              <img style={{transform: matches ? 'rotateZ(-90deg) translateX(7px) translateY(0px)' : 'rotateZ(-90deg) translateX(7px) translateY(0px)'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/change/arrow.png" alt="" />
                            </div>
                            <div className='ipt2 ch-ipt'>
                              {percentileSegmentation((new BigNumber(isSwitch?buyAmount:payAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()),4)} {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}
                            </div>
                          </div>
                          <div className='text-success'>
                            Congratulations on becoming a part of the {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2} ecology.After owning {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2}, you
                            will have more opportunities to participate in the ecology.
                          </div>
                          <div className='text-success text-success2'>
                            Come earn more {isSwitch?paycoinToken1:isCoin===1?token0:isCoin===2?token1:token2} now.
                          </div>
                          <div className={isCoin==3?'btm isCoin3':'btm'}>
                            {
                              isCoin != 3 ?
                              <>
                                <div className='btn'>
                                  <Button onClick={()=>{
                                    setShowBuyNowSuccess(false)
                                    setIpt1('')
                                  }}>Buy More</Button>
                                </div>
                                <div className='btn'>
                                  <Button onClick={()=>{history.push('/earn/pool')}}>Earn Now</Button>
                                </div>
                                <div className='btn'>
                                  <Button onClick={()=>{history.push('/lottery')}}>Go to Lottery</Button>
                                </div>
                              </> :
                              <>
                                <div className='btn'>
                                  <Button onClick={()=>{history.push('/earn/lpStaking')}}>Lp Staking</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      }
                    </>
                  }
                  </> : 
                  <div style={{height: '500px',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <div>{nodata?'No Data':'loading'}</div>
                  </div>
                }
              </div>
            </main>
          </Mini>
          {/* <div onClick={()=>{
            bb()
          }}>bb</div> */}
        </div>
      </main>
    </Wrapper>
  )
}
