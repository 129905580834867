/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react'
import { Currency, Pair } from '@dreamfinance-libs/sdk'
import { Button, ChevronDownIcon, Text } from '@dreamweb/uikit'
import styled from 'styled-components'
import { darken } from 'polished'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { getDcoinAddress } from 'utils/addressHelpers'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import BigNumber from 'bignumber.js'
import { formatDecimal } from 'utils/math'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
  padding: 0 32px 16px 32px;
`
const CurrencySelect = styled.button<{ selected: boolean, disabled1: boolean }>`
  align-items: center;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  border-radius: 12px;
  outline: none;
  cursor:  ${({ disabled1 }) => (disabled1 ? 'pointer' : 'default')};
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  :focus,
  :hover {
    //background-color: ${({ theme }) => darken(0.05, theme.colors.input)};
  }
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  // padding: 0.75rem 1rem 0 1rem;
  padding: 18px 32px 12px 32px;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  //background-color: ${({ theme }) => theme.colors.background};
  background-color: #323232;
  z-index: 1;
`
const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  //background-color: ${({ theme }) => theme.colors.input};
  // background-color: #ffffff;
  background-color: #323232;
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  disabled?: boolean
  balance?: any
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  disabled,
  balance,
}: CurrencyInputPanelProps) {
  
  // 打开
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useWeb3React()
  const dcoinAddress = getDcoinAddress()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const translatedLabel = label || (disabled?'Disabled':'Input')
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Text fontSize="17px" bold style={{ color: '#999999' }}>
                {translatedLabel}
              </Text>
              <Text
                onClick={onMax}
                fontSize="17px"
                bold
                style={{ display: 'inline', cursor: 'pointer', color: '#999999' }}
              >
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? `Balance: ${selectedCurrencyBalance?.toSignificant(6)}`
                  : 'Balance: -'}
              </Text>
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                style={{ fontSize: '24px', fontWeight: 'normal', color: '#999999' }}
                onUserInput={(val) => {
                  // console.log(157,val)
                  onUserInput(val)
                }}
                disabled={disabled}
              />
              {account && !disabled && currency && showMaxButton && label !== 'To' && (
                <Button onClick={onMax} scale="sm" variant="text" style={{ fontWeight: 'normal' }}>
                  MAX
                </Button>
              )}
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            disabled1={disabled}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                if(disabled){
                  setModalOpen(true)
                }
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <Text id="pair">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text id="pair" style={{ color: '#999999' }}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length
                      )}`
                    : currency?.symbol) || 'Select a currency'}
                </Text>
              )}
              {!disableCurrencySelect && disabled && <ChevronDownIcon color="#999999" />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
