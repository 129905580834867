
/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable  react/react-in-jsx-scope */
/* eslint-disable  import/order */
/* eslint-disable  import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import {useState,useEffect,useRef} from 'react'
import { useParams  } from 'react-router-dom'
import useWeb3 from 'hooks/useWeb3'
import { getChangeToken  } from 'utils/change'
import useToast from 'hooks/useToast'
import { Table, Button, Pagination  } from 'antd'
import { Wrapper } from 'views/Home/indexStyles'
import { TokenText, Mini } from './ReceiveStyles'
import { useWeb3React } from '@web3-react/core'
import Login from 'components/Login'
import {getBuyList} from 'api/api.js'


const result = sessionStorage.getItem('gas') || 50000
export default function Index(props) {
  
  const { account } = useWeb3React()
  const params = useParams() as any;
  const web3 = useWeb3()
  const changeTokenContract =  getChangeToken(web3, params.changeToken)
  const coinContract =  getChangeToken(web3, params.token)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );  

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  })


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  const newGetBuyList = async () => {
    const res = await getBuyList({
      funcContract: params.changeToken,
      tokenContract: params?.token,
      from: null,
      pageNo: 1,
      pageSize: 20,
    })
    setPagination({
      current: res.pageNo,
      pageSize: res.pageSize,
      total: res.totalSize
    })
    
    try {
      const arrRes = await Promise.all(
        res?.list.map(async (item, index)=>{
          // 查询 用户持有币数量
          const amount = await coinContract.methods.balanceOf(item.from).call()
          // 转换 18 位小数
          const amount1 = web3.utils.fromWei(amount, 'ether')
          

          return {
            key: item.from,
            name: item.tokenSymbol,
            id: item.id,
            address: item.from,
            amount: amount1,
            preAmount: amount
          }
        })
      )
      setDataSource(arrRes)
    } catch (error) {
      console.log(16, error)
    }
    console.log(res)
  }

  useEffect(()=>{
    newGetBuyList()
  }, [])

  const [dataSource, setDataSource] = useState([
    // {
    //   key: '1',
    //   name: 'newToken',
    //   id: 32,
    //   address: '0x0000000000000000000000000000000000000000',
    // },
  ])

  const [loading, setLoading] = useState(false)

  // function safeExigencyTransfer(address token, address from, uint256 amount) Do 类型接口

  const handleTransfer = async (e) => {
    console.log(128, account, params?.token,e.address,e.preAmount)
    setLoading(true)
    try {
      await changeTokenContract.methods.safeExigencyTransfer(params?.token,e.address,e.preAmount).send({
        from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      }).then((res)=>{
        newGetBuyList()
        toastSuccess('success')
        setLoading(false)
      }).catch((err)=>{
        console.log(155, err)
        toastError('error')
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }



  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'address',
    },
    {
      title: 'operate',
      render: (_, record) => {
        return (
          <div>
            {
              account ?
              <Button loading={loading} onClick={()=>{handleTransfer(record)}}>emergency</Button> :
              <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div style={{width: '340px',height: '56px',background: 'rgb(255, 255, 255)', marginTop: '10px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect</div>
              </Login>
            }
          </div>
        )
      },
    },
  ]

  // 获取列表数据
  // const getBuyList = async () => {
  //   try {
  //     const coinNameRes = await coinContract.methods.name().call()
  //     console.log('changeTokenContract 调用 getBuyList', changeTokenContract, '传参', params?.token, 10, 1)
  //     const res = await changeTokenContract.methods.getBuyList(params?.token, 10, 1).call()
  //     const arrRes = await Promise.all(
  //       res.map(async (item, index)=>{
  //         // 查询 用户持有币数量
  //         const amount = await coinContract.methods.balanceOf(item).call()
  //         // 转换 18 位小数
  //         const amount1 = web3.utils.fromWei(amount, 'ether')
          

  //         return {
  //           key: item,
  //           name: coinNameRes,
  //           id: index+1,
  //           address: item,
  //           amount: amount1,
  //           preAmount: amount
  //         }
  //       })
  //     )
  //     setDataSource(arrRes)
  //   } catch (error) {
  //     console.log(16, error)
  //   }
  // }

  // useEffect(()=>{
  //   getBuyList()
  // }, [])

  const onSelectChange = (page) => {
    newGetBuyListPage(page)

  };

  
  const newGetBuyListPage = async (page) => {
    const res = await getBuyList({
      funcContract: params.changeToken,
      tokenContract: params?.token,
      from: null,
      pageNo: page,
      pageSize: 20,
    })
    setPagination({
      current: res.pageNo,
      pageSize: res.pageSize,
      total: res.totalSize
    })
    
    try {
      const arrRes = await Promise.all(
        res?.list.map(async (item, index)=>{
          // 查询 用户持有币数量
          const amount = await coinContract.methods.balanceOf(item.from).call()
          // 转换 18 位小数
          const amount1 = web3.utils.fromWei(amount, 'ether')
          

          return {
            key: item.from,
            name: item.tokenSymbol,
            id: item.id,
            address: item.from,
            amount: amount1,
            preAmount: amount
          }
        })
      )
      setDataSource(arrRes)
    } catch (error) {
      console.log(16, error)
    }
    console.log(res)
  }

  const [check,setCheck] = useState([])


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setCheck(selectedRows)
    },
  };

  const [loadingS,  setLoadingS] = useState(false)


  // 复数请求
  const handleEmergency = async () => {
    const addressArr = check.map((item)=>{
      return item.address
    })

    const preAmountArr = check.map((item)=>{
      return item.preAmount
    })

    setLoadingS(true)
    try {
      await changeTokenContract.methods.safeExigencyTransfers(params?.token,addressArr,preAmountArr).send({
        from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      }).then((res)=>{
        newGetBuyList()
        toastSuccess('success')
        setLoadingS(false)
      }).catch((err)=>{
        console.log(155, err)
        toastError('error')
        setLoadingS(false)
      })
    } catch (error) {
      setLoadingS(false)
    }
  }



  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <TokenText isMobile={matches}>
            <main>
              <div className='main'>
                <div className='module1'>
                  <div className='text1'>Change</div>
                </div>
              </div>
            </main>
          </TokenText>
          <Mini isMobile={matches}>
            <main>
              <div className="main">
                <Button loading={loadingS} disabled={check.length==0} onClick={handleEmergency}>emergency</Button>
                <Table 
                dataSource={dataSource} 
                columns={columns} 
                pagination={false} 
                rowSelection={{ type: 'checkbox',...rowSelection }}/>
                {/* 分页组件 */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '30px',
                    paddingTop: '20px',}}>
                  <Pagination
                    defaultCurrent={pagination.current}
                    current={pagination.current}
                    total={pagination.total}
                    pageSize={pagination.pageSize}
                    defaultPageSize={pagination.pageSize}
                    onChange={(page) => onSelectChange(page)}
                  />
                </div>
              </div>
            </main>
          </Mini>
        </div>
      </main>
    </Wrapper>
  )
}