/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";
import '../index.css'
import { getPool, getFarm , getLp, getLpStakingNew } from 'utils/change'
import { nanoid } from "nanoid";
import { Decimal } from "decimal.js";
import { getDcoinAddress , 
  getFarmAddress,
  getPoolAddress,
  getLpStakingAddress,
  getNewTokenAddress,
  getUSDTDcoinLpAddress
} from 'utils/addressHelpers'
import useToast from 'hooks/useToast'
import useWeb3 from 'hooks/useWeb3'
import _ from 'lodash'
import { useWeb3React } from '@web3-react/core'
import { getBep20Contract } from 'utils/contractHelpers'
import { useHistory } from 'react-router-dom'
import { useUpdateEffect, useUpdate } from 'ahooks'
import Menu from "./components/Menu";
import Card from "./components/Card";
import { TokenText, ComingSoon } from './indexStyle'
import './index.css'



interface MenuDataTypes {
  staked: boolean;
  pageType: number;
  selectType: string;
  status: boolean;
  searchValue: string;
}


const result = sessionStorage.getItem('gas') || 50000
let poolsG = []
let isFarmsG
const Pool = () => {
  const web3 = useWeb3()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const history = useHistory()
  const { account } = useWeb3React()
  const farmContract =  getFarm(web3)
  const poolContract =  getPool(web3)
  // 换成lp Staking
  const lpStaking =  getLpStakingNew(web3)
  const update = useUpdate();
  const [menuData, setMenuData] = useState<MenuDataTypes>()
  const [poolLenght,setPoolLenght]=useState(0)
  const style = { minHeight: 'calc(100vh - 18rem)' }
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => setWindowSize(window.innerWidth);
  const [poolsCopy, setPoolsCopy] = useState([]);
  const [isFarms,setIsFarms] = useState('Farms')
  const [isUpdata,setIsUpdate]=useState(false)
  const [pools, setPools] = useState([]);
  const [showDetailList, setshowListDetial] = useState<number[]>([])
  const [program, setProgram] = useState();
  const [timer,setTimer]=useState(null)
  const [dataLoading,setDataLoading]=useState(true)
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  // 领取奖励币种地址
  const newTokenAddress = getNewTokenAddress()
  // USDT + dcoin 合成后的Lp 地址
  const usdtDcoinLpAddress = getUSDTDcoinLpAddress()

  //  '0xD960bd586178194e78e87EAc345Cb31060949C41'
  const lpStakingAddress =  getLpStakingAddress()


  const getAa = async () => {
    // 获取 Tokens 数量 view
    lpStaking.methods.allTokensLength().call().then((res)=>{
      console.log('allTokensLength', res)
    })
    // 获取 apy 数量 view
    lpStaking.methods.apy().call().then((res)=>{
      console.log('apy', res)
    })
  }


  useEffect(()=>{
    getAa()
  }, [])

  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => { 
    poolsG = pools
  }, [pools])

  // menu状态
  const getMenuData = (data: MenuDataTypes) => {
    if (data.pageType !== menuData?.pageType) {
      setshowListDetial([])
    }
    setMenuData(data)
  }

  // 展开收起
  const showDetailClick = useCallback((index: number) => {
    if (showDetailList.indexOf(index) !== -1) {
      showDetailList.splice(showDetailList.indexOf(index), 1)
    } else {
      showDetailList.push(index)
    }
    setshowListDetial(showDetailList)
  }, [])



  const changePoolsData = useCallback((pools) => {
    // console.log('menuData', menuData);

    let poolsCopyData = [...pools]

    if(menuData?.selectType==='APY') {
      poolsCopyData.sort(function(a,b){ 
        return b?.apy - a?.apy
       })
    }
    if(menuData?.selectType==="Total staked") {
      poolsCopyData.sort(function(a,b){ 
        return b?.totalStaked - a?.totalStaked
       })
    }
    if(menuData?.staked) {
      poolsCopyData = poolsCopyData.filter((e) => { 
        return e?.staked
       })
    }

    return poolsCopyData
  }, [menuData])
  
  const changeCurrentShowListDeatil = (data) => {
    for (const x of data) {
      if (showDetailList.indexOf(x.index) !== -1) {
        x.showDetail = true
      }
    }
    return data
  }

  
  // 获取token0 - token1
  const getToken = async (index,paycoin) => { 
    if(isFarms==='Farms') {
      // console.log(2)
      // console.log('获取token0 - token1', paycoin)
      const res = await lpStaking.methods.getReverseTokens(paycoin).call()
      // console.log('token0 - token1', res)
      return getIconName(res,index,paycoin)
    } 
    // console.log(3, isFarms==='Farms')
    return getIconName(null,index, paycoin)
    
  }

    
  // getIconName
  const getIconName = async (e,index,paycoin) => { 
    const paycoinContract = await getBep20Contract(paycoin)
    const getPaycoinToken1 = await paycoinContract.methods.symbol().call()
    if(e) {
      const token0Contract = await getBep20Contract(e?.[0])
      const token1Contract = await getBep20Contract(e?.[1])
      const getToken0 = await token0Contract.methods.symbol().call()
      const getToken1 = await token1Contract.methods.symbol().call()
      // console.log(index, 'token0 token1', paycoin, getPaycoinToken1, getToken0, getToken1)
      return {
        tokenArr:[getPaycoinToken1, getToken0, getToken1],
        addArr: [paycoin, e?.[0], e?.[1]]
      }
    } 
      return {
      tokenArr:[getPaycoinToken1],
      addArr: [paycoin]
    }
    
  }

  

  // 获取 Pool 数量 view
  const getPoolLenght = async () => { 
    // console.log('获取 Pool 数量 view')
    try {
      const view = await lpStaking.methods.allTokensLength().call()
      if(view) {
        setPoolLenght(view)
        await getPools(view)
      }
    } catch (error) {
      setPools([])
      setPoolsCopy([])
      setDataLoading(true)
      // console.log(error)
    }
  }

  // 获取指池数据, paycoin 和 rewardcoin, paycoin 就是 LP， rewardcoin 就是 dcoin
  const getPools = async (view) => { 
    try {
      const newPools = await Promise.all(
        [...new Array(+view)].map(async (item,index) => {
          // console.log('开始获取指池数据 ')
          // 获取 apy 数量 view
          const apy = await lpStaking.methods.apy().call()
          const view = {
            apy,
            paycoin: usdtDcoinLpAddress,  // USDT + dcoin 合成后的Lp 地址
            rewardCoin: newTokenAddress,
          }


          const token = await getToken(index,view?.paycoin)
          return new Promise((resolve) => {
            resolve({...view, token, isFarms: isFarms==='Farms', idx: index})
          })
        })
      )
      // console.log(220, !_.isEqual(newPools,poolsG))
      if(!_.isEqual(newPools,poolsG)) {
        setPools(newPools)
        setPoolsCopy(changePoolsData(newPools))
        // console.log('获取指池数据 view', newPools)
      }
    } catch (error) {
      // setPools([])
      // setPoolsCopy([])
      // console.log(231, )
    }
  }
  
  // 获取 已质押得到的奖励数量 view
  const pending = async (index) => { 
    try {
      // console.log('开始获取 已质押得到的奖励数量 view', index, account)
      if(account){
        if(isFarms==='Farms') {
          const {token: {tokenArr,addArr}, isFarms, idx} = poolsCopy[index]
          const tokenAddress = await lpStaking.methods.tokens(
            addArr?.[1]||'0x55d398326f99059fF775485246999027B3197955',
            addArr?.[2]||'0xB84c120c7B96A08fAB69087B289729E453503E82',).call()

          console.log(286, tokenAddress)

          const view = await lpStaking.methods.earned(tokenAddress, account).call()
          return view
        } 
        if(isFarms!=='Farms') {
          const view = await (isFarms==='Farms'?farmContract:poolContract).methods.pending(index, account).call()
          // console.log('获取 已质押得到的奖励数量 view', view)
          return view
        }
      }
      return 0
    } catch (error) {
      // console.log(error)
      return 0
    }
  }

  // 质押  stake
  const deposit = async (index,val) => { 
    try {
      // console.log('开始质押', index, new Decimal(val || 0).mul(new Decimal(1e18)).toFixed())
      await (isFarms==='Farms'?farmContract:poolContract).methods.deposit(index, new Decimal(val || 0).mul(new Decimal(1e18)).toFixed()).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        // value: new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()
      })
      toastSuccess('Stake successful')
      setIsUpdate((pre) => !pre)
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Stake failed')
    }
  }

    
  // 取回 Unstake
  const withdraw = async (index, val) => { 
    try {
      // console.log('开始取回', index, account)
      await (isFarms==='Farms'?farmContract:poolContract).methods.withdraw(index, new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        // value: new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()
      })
      setIsUpdate((pre) => !pre)
      toastSuccess('Unstake successful')
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Unstake failed')
    }
  }

    
  // 领取奖励 Harvest
  const claim = async (index) => { 
      if(isFarms==='Farms') {
        const {token: {tokenArr,addArr}, isFarms, idx} = poolsCopy[index]
        console.log(293, addArr)
        const tokenAddress = await lpStaking.methods.tokens(
          addArr?.[1]||'0x55d398326f99059fF775485246999027B3197955',
          addArr?.[2]||'0xB84c120c7B96A08fAB69087B289729E453503E82',).call()
          console.log('tokens', lpStaking.methods.harvest)
          await lpStaking.methods.harvest(tokenAddress).send({
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      } else {
        try {
          // console.log('开始领取奖励', index, (isFarms==='Farms'?farmContract:poolContract).methods)
          await (isFarms==='Farms'?farmContract:poolContract).methods.harvest(index).send({
            from: account, 
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          })
          setIsUpdate((pre) => !pre)
          toastSuccess('Harvest successful')
        } catch (error:any) {
          toastWarning(error?.message ? error?.message : 'Harvest failed')
        }
      }
  }



  
  useUpdateEffect(() => {
    setPoolsCopy(changePoolsData(pools))
  }, [menuData])

  const timingAcquisition = async () => { 
    setDataLoading(true)
    await getPoolLenght() 
    setDataLoading(false)
  }

  useEffect(() => { 
    clearInterval(timer)
    let newTimer
    if(poolLenght) {
      newTimer = setInterval(() => {
        // console.log('pools', poolsG, poolLenght)
        getPools(poolLenght)
      }, 2500);
      setTimer(newTimer)
    }
    return () => { 
      clearInterval(newTimer)
     }
  }, [poolLenght, account, isFarms])
    

  useEffect(() => {
    if(menuData){
      timingAcquisition()
    }
  }, [isFarms,isUpdata, menuData,account])

  
  useEffect(() => { 
    const unListen = history.listen((e) => {
      if(isFarmsG!=='Farms'){
        setDataLoading(true)
        setPools([])
        setPoolsCopy([])
      }
      isFarmsG='Farms'
      setIsFarms('Farms')
     })
    return ()=>{
      unListen()
    }
  }, [account])

  return (
    <div className="pools-box" style={style}>
      <TokenText isMobile={matches}>
        <main>
          <div className='main'>
            <div className='module1'>
              <div className='text1'>
                LP Staking
              </div>
              <div className='text2-box'>
                <div className='text2-1'>Stake {isFarms==='Farms'?'LP':''} {isFarms==='Farms'?'tokens':'tokens'} to earn.</div>
              </div>
            </div>
          </div>
        </main>
      </TokenText>
      {
        // isFarms==='Farms' ?
        // <ComingSoon>
        //   <div className="main">
        //     <img src="/images/ComingSoon.png" alt="" />
        //     <div>
        //       Coming Soon
        //     </div>
        //   </div>
        // </ComingSoon> :
        <>
          <Menu changeData={getMenuData} isFarms={isFarms==='Farms'}/>
          {
            poolsCopy.length === 0 &&
            <div className="flex flex-col justify-center items-center mt-10 lg:px-32 lg:py-20">
              <span className="text-text2 text-10xl">{dataLoading?'loading':poolsCopy.length === 0?'No Data':''}</span>
            </div>
          }
          {
            !dataLoading && poolsCopy.length &&
            <div className="lg:container lg:max-w-screen-xl lg:mx-auto px-8 lg:px-32 py-10 lg:py-20 text-text1">
              <div className={menuData?.pageType === 1 ? `grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-8 gap-x-20 ` : 'rounded-20 overflow-hidden'}>
                {
                  poolsCopy instanceof Array && poolsCopy.map((item: any, index: React.Key) => {
                    return menuData?.pageType === 1 ? (
                      <Card lpStaking={lpStaking} getPending={pending} idx={index} showDetailClick={showDetailClick} menuData={menuData} deposit={deposit} withdraw={withdraw} claim={claim} itemData={item} key={nanoid()} program={program} setIsUpdateCard={setIsUpdate} />
                    ) : null
                  })
                }
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default Pool