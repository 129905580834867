/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { JSBI, Pair, Percent } from '@dreamfinance-libs/sdk'
import { Button, Card as UIKitCard, CardBody, Text } from '@dreamweb/uikit'
import { darken } from 'polished'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link , useHistory } from 'react-router-dom'
import { Decimal } from "decimal.js";
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance as useTokenBalanceNew } from 'hooks/useTokenBalance'
import { useTotalSupply } from '../../data/TotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Card from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const FixedHeightRowCenter = styled(RowBetween)`
  margin-top: 10px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HoverCard = styled(Card)`
  border: 1px solid #efefef;
  /* border: 1px solid ${({ theme }) => theme.colors.invertedContrast}; */
  :hover {
    /* border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)}; */
  }
`

interface PositionCardProps {
  pair: Pair
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  showBorder?: string

}

export function MinimalPositionCard({balanceNew=0, isUpdate, address,  pair, showUnwrapped = false, showBorder = 'none', currency0=null, currency1=null }: any) {
  const { account } = useWeb3React()
  const balance = useTokenBalanceNew(address, isUpdate);
  console.log(56, balance, address)
  const history = useHistory()

  return (
    <>
      {currency0?.symbol && (
        <UIKitCard style={{ borderRadius: '20px', border: showBorder,  background: '#232323' }}>
          <CardBody>
            <AutoColumn gap="12px">
              <FixedHeightRow>
                <RowFixed>
                  <Text style={{ textTransform: 'uppercase', fontWeight: 600 }} fontSize="22px" color="text">
                    LP Tokens in your Wallet
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <FixedHeightRow onClick={() => {
                // 
              }}>
                <RowFixed>
                  <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
                  <Text fontSize="14px">
                    {currency0?.symbol}/{currency1?.symbol}
                  </Text>
                </RowFixed>
                <RowFixed>
                  <Text fontSize="14px">{new Decimal(balanceNew||balance.toFixed()||0).div(1e18).toNumber() }</Text>
                </RowFixed>
              </FixedHeightRow>
              {/* <FixedHeightRowCenter>
                <RowFixed>
                  <Text style={{ fontWeight: 500, color: '#D1545C' }} fontSize="14px" color="text">
                    <div className={new Decimal(balance.toFixed()||0).div(1e18).toNumber() > 0?'corsor-pointer':'corsor-not-allowed'} onClick={()=>{
                      if(new Decimal(balance.toFixed()||0).div(1e18).toNumber() > 0) {
                        history.push(`/remove/${currency0?.address}/${currency1?.address}`)
                      }
                      }}>Remove liquidity</div>
                  </Text>
                </RowFixed>
              </FixedHeightRowCenter> */}
            </AutoColumn>
          </CardBody>
        </UIKitCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { account } = useWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]

  return (
    <HoverCard>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}</Text>
          </RowFixed>
          <RowFixed>
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize="14px" fontWeight="600">Pooled {currency0.symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text color="textSubtle" fontSize="14px" fontWeight="600" ml="6px">{token0Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize="14px" fontWeight="600">Pooled {currency1.symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text color="textSubtle" ml="6px" fontSize="14px" fontWeight="600">{token1Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize="14px" fontWeight="600">Your pool tokens:</Text>
              <Text color="textSubtle" fontSize="14px" fontWeight="600">{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize="14px" fontWeight="600">Your pool share:</Text>
              <Text color="textSubtle" fontSize="14px" fontWeight="600">{poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-'}</Text>
            </FixedHeightRow>

            <RowBetween marginTop="30px">
              <Button as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`} style={{ width: '48%', borderRadius: '20px' }}>
                Add
              </Button>
              <Button
                as={Link}
                style={{ width: '48%', borderRadius: '20px' }}
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                Remove
              </Button>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  )
}
