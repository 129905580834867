/* eslint-disable import/prefer-default-export */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``

const TokenText = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main {
      width: 100%;
      .module1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text1 {
          text-align: center;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '32px' };
          font-family: Nirmala UI-Bold;
          font-weight: 400;
          color: #FFFFFF;
        }
        .text2-box {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 29px;
          width: ${({isMobile}) => isMobile ?  '500px' : '100%' };
          .text2-1 {
            font-size: ${({isMobile}) => isMobile ?  '20px' : '20px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
          .text2-2 {
            font-size:  ${({isMobile}) => isMobile ?  '20px' : '24px' };
            font-family: Consolas-Regular, Consolas;
            font-weight: 400;
            color: #FACE9C;
          }
        }
      }
    }
  }
`

const Mini = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main { 
      margin-top: 58px;
      background: #FAFAFA;
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      padding: 40px 16px;
      .ant-select-selector,.ant-pagination-options {
        display: none;
      }
    }
  }
`;

// background: #232323;


export {
  StyleBase,
  TokenText,
  Mini
}