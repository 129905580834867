/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/no-duplicates */
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Login from 'components/Login'
import useWeb3 from 'hooks/useWeb3'
import { getLpStakingAddress , 
  getFarmAddress,
  getPoolAddress,
  getNewTokenAddress
} from 'utils/addressHelpers'
import { accMinus, formatDecimal } from 'utils/math'
import  { Button  } from 'antd'
import { useHistory } from "react-router-dom";
import { getBep20Contract } from 'utils/contractHelpers'
import { percentileSegmentation } from 'utils/utils'
import { Decimal } from "decimal.js";
import _ from 'lodash'
import { useWeb3React } from '@web3-react/core'
import Tokens from './Tokens'
import AddLp from '../../AddLp/index'
import RemoveLp from '../../RemoveLp/index'
import Modal from 'components/Modal/LpModal'
import { getLinkBnbLink } from 'utils/addressHelpers'

const Card = (props: any) => {

  const { lpStaking, getPending, claim, itemData, setIsUpdateCard } = props
  const web3 = useWeb3()
  const history = useHistory()
  const {token: {tokenArr,addArr}, isFarms, idx} = itemData
  const [data, setData] = useState(itemData)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAdd,setIsAdd] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHarvestLoading,setIsHarvestLoading]=useState(false)
  const [earnedNum, setEarnedNum] = useState(0)
  const [timer,setTimer]=useState(null)
  const [isAddLp, setIsAddLp] = useState(false)
  const [showListDetial, setshowListDetial] = useState<boolean>(data.showDetail)
  const { account } = useWeb3React()
  const [lpToken,setLpToken]=useState<any>('')
  const [isUpdate,setIsUpdate]=useState<any>('')
  const [balance, setBalance] = useState(0)
  const lpStakingAddress = getLpStakingAddress()
  const newTokenAddress = getNewTokenAddress()


    // 获取tokens 合约地址 view
    const tokens = async () => {
      try {
        const view = await lpStaking.methods.tokens(
          addArr?.[1]||'0x55d398326f99059fF775485246999027B3197955',
          addArr?.[2]||'0xB84c120c7B96A08fAB69087B289729E453503E82',).call()
          console.log('tokens', addArr?.[1], addArr?.[2], view)
          setLpToken(view)
      } catch (error) {
        // console.log(error)
      }
    }

    const getBalance = async () => {
      const contract = getBep20Contract(lpToken, web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(res)
    }


    useEffect(()=>{
      if(lpToken) {
        getBalance()
      }
    }, [lpToken, isUpdate])

  
    useEffect(() => { 
      if(account&&addArr?.[1]&&addArr?.[2]) {
        tokens()
      }
    }, [account,addArr?.[1],addArr?.[2]])

  
  useEffect(() => {
    setData(itemData)
  }, [itemData, account])
  

  useEffect(() => {
    const fn = async () => {
      const num = await getPending(idx)
      setEarnedNum(num)
    }

    fn()
    clearInterval(timer)
    const newTimer = setInterval(() => fn(), 10000)
    setTimer(newTimer)
    console.log(171, timer, newTimer)
    return () => clearInterval(newTimer)
  }, [])

  return (
    <div className="md:w-96 w-full mx-auto">
      <div className="card px-4 lg:px-5 py-8 bg-black2 lg:rounded-20 rounded-12 relative ">



        <div className="flex justify-start items-center">
          <div className="relative">
              <img  src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms?tokenArr?.[1]||'USDT':tokenArr?.[0]||'DCOIN'}.png`} alt="" className="" style={{width: '45px'}}/>
            <div className="absolute -right-2 -bottom-2 w-6 h-6 rounded-c bg-btn">
              <img  src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms?tokenArr?.[2]||'DCOIN':'DCOIN'}.png`} alt="" />
            </div>
          </div>
          <div className="flex flex-col ml-6">
            {
              isFarms ?
              <span className="text-6xl text-text2">{tokenArr?.[1]}/{tokenArr?.[2]} LP</span>:
              <span className="text-6xl text-text2">Stake  {tokenArr?.[0]}</span>
            }
          </div>
        </div>
        <div className="border rounded-8 p-4 mt-4">
          <div className="flex justify-between items-center ">
            <span className="text-4xl">APY:</span>
            <span className="text-4xl text-text2">{data?.apy/100}%</span>
          </div>
          <div className="flex justify-between items-center mt-3">
            <span className="text-4xl">Earn:</span>
            <span className="text-4xl text-text2">{'Change'}</span>
          </div>
        </div>

        <div style={{border: '2px solid',padding: '14px',borderRadius: '8px',}} className="mt-4">
          <span className="text-text2 text-4xl">{'Change'} EARNED</span>
          <div className="flex justify-between items-center mt-2">
            <div className="flex flex-col text-4xl">
              <span className="text-text2 text-8xl">{+earnedNum !== 0 ? formatDecimal(new Decimal(earnedNum).div(1e18).toFixed(9), 8)||0 : '0.00000000'}</span>
              {/* <span>{`~ ${percentileSegmentation(earnedNum, 3) || 0} USDC`}</span> */}
            </div>
            <Button 
              disabled={!+earnedNum}  
              loading={isHarvestLoading}
              onClick={async ()=>{
                try {
                  setIsHarvestLoading(true)
                  await claim(idx)
                  setIsHarvestLoading(false)
                } catch (error) {
                  setIsHarvestLoading(false)
                }
                setIsHarvestLoading(false)
              }} 
              style={{width: '75px',height: '26px', background: isLoading||!+earnedNum? '#6D6D6D':'#fff', color: isLoading||!+earnedNum? '#B9B9B9':'#000', borderRadius: '4px',border:'none'}} className="flex justify-center items-center w-28 h-12 rounded-12">Harvest</Button>
          </div>
        </div>
        <div style={{border: '2px solid',padding: '14px',borderRadius: '8px',marginTop:'12px'}}> 
          <div  className="flex justify-between items-center mt-3">
          <span className="text-4xl text-text2">STAKED</span>
          </div>
          {
            /** *
             *  1. 未授权，仅展示授权按钮，置灰
              * 2. 授权但未质押，仅展示stake按钮，置灰
              * 3. 存在质押，展示质押数和unstake按钮，点击执行赎回（全部），执行完后无质押，置灰按钮
            */
          }
          {
            !account ? (
              <Login
                  style={{width: '100%'}}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div style={{width: '340px',height: '56px',background: 'rgb(255, 255, 255)', marginTop: '10px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
              </Login>
            ) : (
              <div className="border border-line rounded-12 py-4 flex flex-col items-start justify-around w-full mr-8">
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-col w-full">
                    <span className="text-text2 text-10xl">{formatDecimal(new Decimal(balance).div(1e18).toFixed(), 4)}</span>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button disabled={+balance===0} onClick={()=>{
                      setIsAdd(false)
                      setIsAddLp(true)
                    }} style={{border: '1px solid #fff', color: '#fff'}} className="flex justify-center items-center w-12 h-12 mr-4 rounded-12  border text-text2">-</Button>
                    <Button  onClick={()=>{
                      setIsAdd(true)
                      setIsAddLp(true)
                    }} style={{border: '1px solid #fff', color: '#fff'}} className="flex justify-center items-center w-12 h-12 rounded-12 border text-text2">+</Button>
                  </div>
                </div>
              </div>
            )
          }
        </div>

        <div className="noselect w-full flex justify-center items-center mt-4 lg:cursor-pointer" onClick={() => { setshowListDetial(!showListDetial)
            }}>
          {
            showListDetial ? (
              <div className="flex justify-center items-center">
                <span className="text-text2 text-4xl font-medium mr-2">Hide</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                  <g transform="translate(6 3.667) rotate(180)">
                    <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                  </g>
                </svg>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <span className="text-text4 text-4xl font-medium mr-2">Details</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                  <g transform="translate(0 0)">
                    <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                  </g>
                </svg>
              </div>
            )
          }
        </div>

        <div className={`${showListDetial ? 'h-auto' : 'h-0 overflow-hidden'}`}>
          <div style={{backgroundColor: '#222'}} className="rounded-8 p-4 bg-black4 mt-4">
            {/* <div className="flex justify-between items-center text-4xl">
              <span className="text-4xl text-text2">Start Time:</span>
              <span className="text-4xl text-text2">08:11:32</span>
            </div> */}
            <div onClick={()=> {
              window.open(`${getLinkBnbLink()}token/${newTokenAddress}`)
            }}  style={{textAlign: 'center'}} className="hover-link border border-btn rounded-20 text-2xl py-1 text-text2">See Token Info</div>
            <div onClick={()=> {
              window.open(`${getLinkBnbLink()}address/${lpStakingAddress}`)
            }}  style={{textAlign: 'center'}} className="hover-link border border-btn rounded-20 text-2xl py-1 text-text2">View Contract</div>
          </div>
        </div>

      </div>

      
      <Modal isOpen={isAddLp} onDismiss={()=>{setIsAddLp(false)}} maxHeight={150}>
        {
          isAdd ?
          <AddLp setIsUpdateCard={setIsUpdateCard} setIsUpdate={setIsUpdate} onDismiss={()=>setIsAddLp(false)} currencyIdA={addArr?.[1]} currencyIdB={addArr?.[2]} /> : 
          <RemoveLp setIsUpdateCard={setIsUpdateCard} setIsUpdate={setIsUpdate} onDismiss={()=>setIsAddLp(false)} currencyIdA={addArr?.[1]} currencyIdB={addArr?.[2]} />
        }
      </Modal>
    </div>
  )
}
export default observer(Card)